import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { parseISO } from 'date-fns';
import {
  IStyledProps,
  LocationStationLookup,
  NotificationType,
} from '../../shared';
import { CleanRow, HR } from '../shared';
import { AuthContext, useMatchingMedia, mediaQueryMap } from '../../shared';
import { ViewNotification } from './ViewNotification';
/*import { mockViewNotifications } from './mock-view-notifications';*/
import { formatInTimeZone } from 'date-fns-tz';
import {
  /*ViewNotificationsData,*/
  ViewNotificationData,
  ViewNotificationPreferenceData,
} from './view-notifications-data.model';
import styled from '@emotion/styled';
import { Button } from '@swacl/core';
import {
  GetSubscriptionsSearchResponse,
  GetSubscriptionsSearchResponseSubscriptionComponent,
  GetFlightDetailGetResponse,
} from '@crew/fsm-mobility-models-library';
import { backendApiService } from '../../shared/services/http-service';
import { UpdateNotification } from '../update-notification';
import { DeleteNotification } from '..';

interface Props {
  handleCreateScrollClick: () => void;
  attachSearch: (callback: () => void) => void;
  setSearchCallComplete: (flag: boolean) => void;
  locationStationLookup: LocationStationLookup;
}

const ViewNotificationsBase: FC<IStyledProps & Props> = ({
  className = '',
  handleCreateScrollClick,
  attachSearch,
  setSearchCallComplete,
  locationStationLookup,
}) => {
  const authData = useContext(AuthContext);

  const matchesMD = useMatchingMedia('md');
  const [propOpenUpdateModal, setPropOpenUpdateModal] =
    useState<boolean>(false);
  const [updateModalNotificationData, setUpdateModalNotificationData] =
    useState<ViewNotificationData | null>(null);
  const [deleteModalNotificationData, setDeleteModalNotificationData] =
    useState<ViewNotificationData | null>(null);
  const [propOpenDeleteModal, setPropOpenDeleteModal] =
    useState<boolean>(false);
  const [notifications, setNotifications] = useState<ViewNotificationData[]>(
    []
  );

  const setNotificationsPreferences = (
    notification: ViewNotificationData,
    newNotificationPreferences: ViewNotificationPreferenceData[]
  ) => {
    const newNotificaitons = notifications;
    const notificationToUpdate = newNotificaitons.find(
      (n) => n === notification
    );
    if (notificationToUpdate) {
      notificationToUpdate.notificationPreferences = newNotificationPreferences;
      setNotifications(newNotificaitons);
    }
  };

  const openUpdateModal = (input: ViewNotificationData) => {
    setUpdateModalNotificationData(input);
    setPropOpenUpdateModal(true);
  };

  const closeUpdateModal = () => {
    setPropOpenUpdateModal(false);
    setUpdateModalNotificationData(null);
  };

  const openDeleteModal = (notification: ViewNotificationData) => {
    setPropOpenDeleteModal(true);
    setDeleteModalNotificationData(notification);
  };

  const closeDeleteModal = () => {
    setPropOpenDeleteModal(false);
    setDeleteModalNotificationData(null);
  };

  const searchApi = useCallback(async () => {
    try {
      console.debug(
        `[ViewNotifications] (searchApi) getting subscriptions for ${authData.userId}`
      );
      const response =
        await backendApiService.get<GetSubscriptionsSearchResponse>(
          `/subscriptions/search`,
          {
            params: { crew_member_id: authData.userId ?? 'N/A' },
          }
        );
      if (response.status === 200) {
        const subscriptions = response.data.subscriptions;
        console.debug(
          '[ViewNotifications] (searchApi) subscriptions',
          subscriptions
        );
        const notificationArray: ViewNotificationData[] = subscriptions.reduce(
          (
            previous: ViewNotificationData[],
            notification: GetSubscriptionsSearchResponseSubscriptionComponent
          ) => {
            console.debug('****Search Reduce Previous****', [...previous]);
            const flightLegKey =
              `${notification.departure_date.split('T')[0].replace(/-/g, '')}` +
              `-${notification.flight_number}` +
              `-${notification.departure_station}`;
            if (
              previous.some(
                (value: ViewNotificationData) =>
                  value.flightLegKey === flightLegKey
              )
            ) {
              console.debug('***Search Reduce Found***', flightLegKey);
              const previousNotificationIndex = previous.findIndex(
                (value: ViewNotificationData) =>
                  value.flightLegKey === flightLegKey
              );
              const previousNotification = previous[previousNotificationIndex];
              previous.splice(previousNotificationIndex, 1, {
                ...previousNotification,
                notificationPreferences: [
                  ...previousNotification.notificationPreferences,
                  {
                    notificationType:
                      notification.channel_type as NotificationType,
                    notificationAddress: notification.channel_address,
                  },
                ],
              });
            } else {
              console.debug('****Search Reduce Not Found', flightLegKey);
              previous.push({
                flightLegKey: flightLegKey,
                flightNumber: notification.flight_number,
                departureDatetime: parseISO(notification.departure_date),
                departureCode: notification.departure_station,
                notificationPreferences: [
                  {
                    notificationType:
                      notification.channel_type as NotificationType,
                    notificationAddress: notification.channel_address,
                  },
                ],
              });
            }
            return previous;
          },
          []
        );

        console.debug(
          '[ViewNotifications] (searchApi) notificationArray',
          notificationArray
        );

        const dateFilterStartDate = new Date();

        console.debug(
          '[ViewNotifications] (searchApi) startDate',
          dateFilterStartDate
        );

        // Filter out subscriptions from flights that departed on a past day
        const dateFiltNotificationArray = notificationArray.filter((a) => {
          console.debug(
            '[ViewNotification] (searchApi) <filter>',
            a.departureDatetime,
            a.departureDatetime.getTime()
          );
          return (
            a.departureDatetime.getTime() >=
            dateFilterStartDate.setHours(0, 0, 0, 0)
          );
        });

        console.debug(
          '[ViewNotifications] (searchApi) date filtered array',
          dateFiltNotificationArray
        );

        // to avoid passing references to the notification objects
        // create a deeper copy of the array
        const cloned = dateFiltNotificationArray.map((x) => ({ ...x }));
        setNotifications(cloned);
        // details only fetched for subscriptions today and beyond
        sortNotifications(cloned);
      }
    } catch (err) {
      console.log(err);
      setNotifications([]);
    }
    setSearchCallComplete(true);
  }, [authData, setSearchCallComplete, setNotifications]);

  const sortNotifications = async (
    notificationArray: ViewNotificationData[]
  ) => {
    await Promise.all(
      notificationArray.map(async (notification: ViewNotificationData) => {
        const flightDetailResponse =
          await backendApiService.get<GetFlightDetailGetResponse>(
            `/flightdetail/${notification.flightLegKey}`
          );

        const departureDate = notification.departureDatetime;
        const departureDateTime = flightDetailResponse.data
          .current_departure_time
          ? parseISO(flightDetailResponse.data.current_departure_time)
          : departureDate;
        notification.departureDateLocal = flightDetailResponse.data
          .departure_station_time_zone
          ? formatInTimeZone(
              departureDateTime,
              flightDetailResponse.data.departure_station_time_zone,
              'E, MMM dd, yyyy'
            )
          : formatInTimeZone(
              departureDateTime,
              'America/Chicago',
              'E, MMM dd, yyyy'
            );
        const arrivalDateTime = flightDetailResponse.data.current_arrival_time
          ? parseISO(flightDetailResponse.data.current_arrival_time)
          : undefined;
        notification.departureDatetime = departureDateTime;
        notification.departureCode = flightDetailResponse.data
          .departure_station_code
          ? flightDetailResponse.data.departure_station_code
          : notification.departureCode;
        notification.departureGate = flightDetailResponse.data.departure_gate;
        notification.departureTimeZone =
          flightDetailResponse.data.departure_station_time_zone;
        notification.departureTimeLocal = flightDetailResponse.data
          .departure_station_time_zone
          ? formatInTimeZone(
              departureDateTime,
              flightDetailResponse.data.departure_station_time_zone,
              'HH:mm zzz'
            )
          : formatInTimeZone(departureDateTime, 'America/Chicago', 'HH:mm zzz');
        notification.arrivalDatetime = arrivalDateTime;
        notification.arrivalCode =
          flightDetailResponse.data.arrival_station_code;
        notification.arrivalGate = flightDetailResponse.data.arrival_gate;
        notification.arrivalTimeZone =
          flightDetailResponse.data.arrival_station_time_zone;
        notification.arrivalTimeLocal = arrivalDateTime
          ? flightDetailResponse.data.arrival_station_time_zone
            ? formatInTimeZone(
                arrivalDateTime,
                flightDetailResponse.data.arrival_station_time_zone,
                'HH:mm zzz'
              )
            : formatInTimeZone(arrivalDateTime, 'America/Chicago', 'HH:mm zzz')
          : '';
        notification.flightDetailSet = true;
      })
    );

    const timeFilterStartDate = new Date();
    const cutoffTime = timeFilterStartDate.getTime() - 3 * 60 * 60 * 1000;

    // Filter out subscriptions from flights that have departed at least 3 hours in the past
    const timeFiltNotificationArray: ViewNotificationData[] =
      notificationArray.filter((a) => {
        console.debug('== Time Filter Evaluation ==');
        console.debug(
          '[ViewNotification] (sortNotifications) Local departure time:',
          a.departureTimeLocal
        );
        console.debug(
          '[ViewNotification] (sortNotifications) Local arrival time:',
          a.arrivalTimeLocal
        );
        console.debug(
          '[ViewNotification] (sortNotifications) departure time:',
          a.departureDatetime,
          a.departureDatetime.getTime()
        );
        console.debug(
          '[ViewNotification] (sortNotifications) cutoff epoch time:',
          cutoffTime
        );
        const timeEvaluation = a.departureDatetime.getTime() >= cutoffTime;
        console.debug(
          '[ViewNotification] (sortNotifications) time filter evaluation:',
          timeEvaluation
        );
        return timeEvaluation;
      });

    console.debug(
      '[ViewNotifications] (sortNotifications) time filtered array:',
      timeFiltNotificationArray
    );

    // Sort subscriptions from earliest to latest departure date
    timeFiltNotificationArray.sort(
      (a, b) => a.departureDatetime.getTime() - b.departureDatetime.getTime()
    );
    // to avoid passing references to the notification objects
    // create a deeper copy of the array
    const cloned = timeFiltNotificationArray.map((x) => ({ ...x }));
    setNotifications(cloned);
  };

  // Will automatically run on update to authData
  useEffect(() => {
    console.debug('[ViewNotifications] <authData> update detected', authData);
    if (authData.authed) searchApi();
    else setSearchCallComplete(true);
  }, [authData, searchApi, setSearchCallComplete]);

  useEffect(() => {
    if (attachSearch) {
      attachSearch(searchApi);
    }
  }, [attachSearch, searchApi]);

  return (
    <div
      className={`grid__column-12--sm grid__column-6--md grid__column-6 ${className}`}
    >
      <CleanRow>
        <div
          className={`grid__column-9--sm grid__column-12--md grid__column-12`}
        >
          <h3>My Notifications ({notifications.length})</h3>
        </div>
        {!matchesMD ? (
          <div className={`grid__column-3--sm`}>
            {' '}
            <Button
              id={'create-scroll'}
              buttonType={'tertiary'}
              onClick={() => handleCreateScrollClick()}
            >
              Create
            </Button>
          </div>
        ) : null}
      </CleanRow>
      {notifications.length > 0 ? (
        notifications.map((notification) => {
          return (
            <ViewNotification
              key={notification.flightLegKey}
              viewNotificationData={notification}
              updateModalNotificationData={openUpdateModal}
              deleteModalNotificationData={openDeleteModal}
              locationStationLookup={locationStationLookup}
            />
          );
        })
      ) : (
        <HR />
      )}

      <UpdateNotification
        propOpenModal={propOpenUpdateModal}
        closeFn={closeUpdateModal}
        callSearch={searchApi}
        viewNotificationData={updateModalNotificationData}
        setNotificationsPreferences={setNotificationsPreferences}
      />
      <DeleteNotification
        openModal={propOpenDeleteModal}
        closeFn={closeDeleteModal}
        notification={deleteModalNotificationData}
        callSearch={searchApi}
      />
    </div>
  );
};

export const ViewNotifications = styled(ViewNotificationsBase)`
  ${mediaQueryMap['md']} {
    max-height: calc(100vh - 175px);
    overflow-y: auto;
  }

  margin: 0px;
  margin-top: 16px;
  ${mediaQueryMap['md']} {
    margin: 16px;
  }
  ${mediaQueryMap['lg']} {
    margin: 24px;
  }

  padding: 8px 16px;
  ${mediaQueryMap['lg']} {
    padding: 24px;
  }

  background-color: var(--global-panel-color);
`;
