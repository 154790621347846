import styled from '@emotion/styled';
import { Button } from '@swacl/core';
import { FC } from 'react';
import './LogoutButton.css'; // Tell webpack that Button.js uses these styles

const LogoutButtonComponent = styled(
  ({
    className = '',
    logout,
    isPrimary = true,
  }: {
    className?: string;
    logout: () => void;
    isPrimary: boolean;
  }) => {
    className = isPrimary ? 'primary' : 'secondary';
    return (
      <div className={`logout-button-${className}`}>
        <Button
          id={`logout-${className}`}
          buttonType={'secondary'}
          onClick={() => logout()}
        >
          Logout
        </Button>
      </div>
    );
  }
)``;

interface Props {
  logout: () => void;
  isPrimary: boolean;
}

export const LogoutButton: FC<Props> = ({ logout, isPrimary }) => {
  return <LogoutButtonComponent logout={logout} isPrimary={isPrimary} />;
};
