export const mock_cities = [
  {
    station_code: 'BZN',
    city: 'Bozeman',
    state_code: 'MT',
    station_name: 'Bozeman Yellowstone Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'MSY',
    city: 'New Orleans',
    state_code: 'LA',
    station_name: 'Armstrong New Orleans Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'PSP',
    city: 'Palm Springs',
    state_code: 'CA',
    station_name: 'Palm Springs Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'LIH',
    city: 'Lihue',
    state_code: 'HI',
    station_name: 'Lihue',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'ALB',
    city: 'Albany',
    state_code: 'NY',
    station_name: 'Albany Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'JAX',
    city: 'Jacksonville',
    state_code: 'FL',
    station_name: 'Jacksonville Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'BUF',
    city: 'Buffalo',
    state_code: 'NY',
    station_name: 'Buffalo Niagara Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'DTW',
    city: 'Detroit',
    state_code: 'MI',
    station_name: 'Detroit Metro Wayne Co',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'DCA',
    city: 'Washington D.C.',
    state_code: 'DC',
    station_name: 'Ronald Reagan Washington Natl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'IAH',
    city: 'Houston',
    state_code: 'TX',
    station_name: 'George Bush Intercontinental',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'PIT',
    city: 'Pittsburgh',
    state_code: 'PA',
    station_name: 'Pittsburgh Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'PUJ',
    city: 'Punta Cana',
    state_code: '',
    station_name: 'Punta Cana Intl',
    country_name: 'Dominican Republic',
    country_code: 'DO',
  },
  {
    station_code: 'ONT',
    city: 'Ontario (LA)',
    state_code: 'CA',
    station_name: 'Ontario Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'HDN',
    city: 'Steamboat Springs (Hayden)',
    state_code: 'CO',
    station_name: 'Yampa Valley',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'DSM',
    city: 'Des Moines',
    state_code: 'IA',
    station_name: 'Des Moines Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'PVD',
    city: 'Providence',
    state_code: 'RI',
    station_name: 'Green State',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'BDL',
    city: 'Hartford',
    state_code: 'CT',
    station_name: 'Bradley Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'AUA',
    city: 'Aruba',
    state_code: '',
    station_name: 'Queen Beatrix Intl',
    country_name: 'Aruba',
    country_code: 'AW',
  },
  {
    station_code: 'SYR',
    city: 'Syracuse',
    state_code: 'NY',
    station_name: 'Syracuse Hancock Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'CVG',
    city: 'Cincinnati',
    state_code: 'OH',
    station_name: 'Cincinnati/Northern Kentucky Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'IND',
    city: 'Indianapolis',
    state_code: 'IN',
    station_name: 'Indianapolis Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'BOS',
    city: 'Boston',
    state_code: 'MA',
    station_name: 'Logan Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'PVR',
    city: 'Puerto Vallarta',
    state_code: '',
    station_name: 'Lic Gustavo Diaz Ordaz Intl',
    country_name: 'Mexico',
    country_code: 'MX',
  },
  {
    station_code: 'CZM',
    city: 'Cozumel',
    state_code: '',
    station_name: 'Cozumel Intl',
    country_name: 'Mexico',
    country_code: 'MX',
  },
  {
    station_code: 'OMA',
    city: 'Omaha',
    state_code: 'NE',
    station_name: 'Eppley',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'DAL',
    city: 'Dallas',
    state_code: 'TX',
    station_name: 'Dallas Love',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'HNL',
    city: 'Honolulu',
    state_code: 'HI',
    station_name: 'Honolulu Intl (Inouye)',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'SJC',
    city: 'San Jose',
    state_code: 'CA',
    station_name: 'Mineta San Jose Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'ISP',
    city: 'Long Island/Islip',
    state_code: 'NY',
    station_name: 'Long Island MacArthur',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'BZE',
    city: 'Belize City',
    state_code: 'BZ',
    station_name: 'Philip S W Goldson Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'LAS',
    city: 'Las Vegas',
    state_code: 'NV',
    station_name: 'McCarran Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'VPS',
    city: 'Destin/Fort Walton Beach',
    state_code: 'FL',
    station_name: 'Eglin Afb/Destin-Ft Walton Bea',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'MBJ',
    city: 'Montego Bay',
    state_code: '',
    station_name: 'Sangster Intl',
    country_name: 'Jamaica',
    country_code: 'JM',
  },
  {
    station_code: 'BWI',
    city: 'Baltimore/Washington',
    state_code: 'MD',
    station_name: 'Baltimore/Washington Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'HRL',
    city: 'Harlingen',
    state_code: 'TX',
    station_name: 'Valley Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'PAE',
    city: 'Everett',
    state_code: 'WA',
    station_name: 'Snohomish Co',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'SJD',
    city: 'Los Cabos',
    state_code: '',
    station_name: 'Los Cabos Intl',
    country_name: 'Mexico',
    country_code: 'MX',
  },
  {
    station_code: 'SJO',
    city: 'San Jose',
    state_code: '',
    station_name: 'Juan Santamaria Intl',
    country_name: 'Costa Rica',
    country_code: 'CR',
  },
  {
    station_code: 'LGA',
    city: 'New York',
    state_code: 'NY',
    station_name: 'Laguardia',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'RNO',
    city: 'Reno/Tahoe',
    state_code: 'NV',
    station_name: 'Reno/Tahoe Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'EWR',
    city: 'Newark',
    state_code: 'NJ',
    station_name: 'Newark Liberty Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'GEG',
    city: 'Spokane',
    state_code: 'WA',
    station_name: 'Spokane Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'SRQ',
    city: 'Sarasota/Bradenton',
    state_code: 'FL',
    station_name: 'Sarasota-Bradenton Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'ICT',
    city: 'Wichita',
    state_code: 'KS',
    station_name: 'Wichita Eisenhower Natl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'MHT',
    city: 'Manchester',
    state_code: 'NH',
    station_name: 'Manchester-Boston Regional',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'SAN',
    city: 'San Diego',
    state_code: 'CA',
    station_name: 'San Diego Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'MEM',
    city: 'Memphis',
    state_code: 'TN',
    station_name: 'Memphis Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'LIR',
    city: 'Liberia',
    state_code: '',
    station_name: 'Daniel Oduber Quiros Intl',
    country_name: 'Costa Rica',
    country_code: 'CR',
  },
  {
    station_code: 'SLC',
    city: 'Salt Lake City',
    state_code: 'UT',
    station_name: 'Salt Lake City Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'CRP',
    city: 'Corpus Christi',
    state_code: 'TX',
    station_name: 'Corpus Christi Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'OGG',
    city: 'Kahului (Maui)',
    state_code: 'HI',
    station_name: 'Kahului',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'CLE',
    city: 'Cleveland',
    state_code: 'OH',
    station_name: 'Cleveland Hopkins Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'SEA',
    city: 'Seattle/Tacoma',
    state_code: 'WA',
    station_name: 'Seattle-Tacoma Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'EUG',
    city: 'Eugene',
    state_code: 'OR',
    station_name: 'Eugene (Mahlon Sweet)',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'MDW',
    city: 'Chicago',
    state_code: 'IL',
    station_name: 'Chicago Midway Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'RDU',
    city: 'Raleigh/Durham',
    state_code: 'NC',
    station_name: 'Raleigh-Durham Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'MIA',
    city: 'Miami',
    state_code: 'FL',
    station_name: 'Miami Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'ABQ',
    city: 'Albuquerque',
    state_code: 'NM',
    station_name: 'Albuquerque Intl Sunport',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'SAV',
    city: 'Savannah',
    state_code: 'GA',
    station_name: 'Savannah/Hilton Head Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'ELP',
    city: 'El Paso',
    state_code: 'TX',
    station_name: 'El Paso Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'PBI',
    city: 'West Palm Beach',
    state_code: 'FL',
    station_name: 'Palm Beach Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'LAX',
    city: 'Los Angeles',
    state_code: 'CA',
    station_name: 'Los Angeles Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'ROC',
    city: 'Rochester',
    state_code: 'NY',
    station_name: 'Greater Rochester Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'GSP',
    city: 'Greenville-Spartanburg',
    state_code: 'SC',
    station_name: 'Greenville Spartanburg Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'LBB',
    city: 'Lubbock',
    state_code: 'TX',
    station_name: 'Lubbock Preston Smith Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'AMA',
    city: 'Amarillo',
    state_code: 'TX',
    station_name: 'Rick Husband Amarillo Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'BNA',
    city: 'Nashville',
    state_code: 'TN',
    station_name: 'Nashville Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'ORD',
    city: 'Chicago',
    state_code: 'IL',
    station_name: "Chicago O'Hare Intl",
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'SNA',
    city: 'Orange County/Santa Ana',
    state_code: 'CA',
    station_name: 'John Wayne (Orange Co)',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'BHM',
    city: 'Birmingham',
    state_code: 'AL',
    station_name: 'Birmingham-Shuttlesworth Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'FLL',
    city: 'Ft. Lauderdale',
    state_code: 'FL',
    station_name: 'Ft Lauderdale-Hollywood Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'PHX',
    city: 'Phoenix',
    state_code: 'AZ',
    station_name: 'Phoenix Sky Harbor Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'SBA',
    city: 'Santa Barbara',
    state_code: 'CA',
    station_name: 'Santa Barbara Mun',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'FNT',
    city: 'Flint',
    state_code: 'MI',
    station_name: 'Bishop Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'KOA',
    city: 'Kona',
    state_code: 'HI',
    station_name: 'Kona Intl at Keāhole',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'MSP',
    city: 'Minneapolis/St. Paul',
    state_code: 'MN',
    station_name: 'Minneapolis-St Paul Intl (Wold-Chamberlain)',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'BOI',
    city: 'Boise',
    state_code: 'ID',
    station_name: 'Boise Air Terminal (Gowen)',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'ATL',
    city: 'Atlanta',
    state_code: 'GA',
    station_name: 'Hartsfield - Jackson Atlanta Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'RIC',
    city: 'Richmond',
    state_code: 'VA',
    station_name: 'Richmond Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'TUL',
    city: 'Tulsa',
    state_code: 'OK',
    station_name: 'Tulsa Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'SJU',
    city: 'San Juan',
    state_code: 'PR',
    station_name: 'Luis Munoz Marin Intl',
    country_name: 'Puerto Rico',
    country_code: 'PR',
  },
  {
    station_code: 'GCM',
    city: 'Grand Cayman',
    state_code: '',
    station_name: 'Roberts Intl',
    country_name: 'Cayman Islands',
    country_code: 'KY',
  },
  {
    station_code: 'SDF',
    city: 'Louisville',
    state_code: 'KY',
    station_name: 'Louisville Muhammad Ali Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'SFO',
    city: 'San Francisco',
    state_code: 'CA',
    station_name: 'San Francisco Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'SNU',
    city: 'Santa Clara',
    state_code: '',
    station_name: 'Abel Santamaria Intl',
    country_name: 'Cuba',
    country_code: 'CU',
  },
  {
    station_code: 'MEX',
    city: 'Mexico City',
    state_code: '',
    station_name: 'Benito Juarez Intl',
    country_name: 'Mexico',
    country_code: 'MX',
  },
  {
    station_code: 'CLT',
    city: 'Charlotte',
    state_code: 'NC',
    station_name: 'Charlotte Douglas Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'ITO',
    city: 'Hilo',
    state_code: 'HI',
    station_name: 'Hilo Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'MCO',
    city: 'Orlando',
    state_code: 'FL',
    station_name: 'Orlando Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'RSW',
    city: 'Ft. Myers',
    state_code: 'FL',
    station_name: 'Southwest Florida Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'COS',
    city: 'Colorado Springs',
    state_code: 'CO',
    station_name: 'City Of Colorado Springs Mun',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'OKC',
    city: 'Oklahoma City',
    state_code: 'OK',
    station_name: 'Will Rogers World',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'DEN',
    city: 'Denver',
    state_code: 'CO',
    station_name: 'Denver Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'CUN',
    city: 'Cancun',
    state_code: '',
    station_name: 'Cancun Intl',
    country_name: 'Mexico',
    country_code: 'MX',
  },
  {
    station_code: 'TUS',
    city: 'Tucson',
    state_code: 'AZ',
    station_name: 'Tucson Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'SAT',
    city: 'San Antonio',
    state_code: 'TX',
    station_name: 'San Antonio Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'MKE',
    city: 'Milwaukee',
    state_code: 'WI',
    station_name: 'Milwaukee Mitchell Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'LIT',
    city: 'Little Rock',
    state_code: 'AR',
    station_name: 'Clinton Natl (Adams)',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'BUR',
    city: 'Burbank',
    state_code: 'VA',
    station_name: 'Bob Hope',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'PNS',
    city: 'Pensacola',
    state_code: 'FL',
    station_name: 'Pensacola Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'PHL',
    city: 'Philadelphia',
    state_code: 'PA',
    station_name: 'Philadelphia Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'VRA',
    city: 'Varadero',
    state_code: '',
    station_name: 'Juan G. Gomez Intl',
    country_name: 'Cuba',
    country_code: 'CU',
  },
  {
    station_code: 'TPA',
    city: 'Tampa',
    state_code: 'FL',
    station_name: 'Tampa Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'MYR',
    city: 'Myrtle Beach',
    state_code: 'SC',
    station_name: 'Myrtle Beach Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'ECP',
    city: 'Panama City',
    state_code: 'FL',
    station_name: 'Northwest Florida Beaches Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'MAF',
    city: 'Midland',
    state_code: 'TX',
    station_name: 'Midland Intl Air/Space Port',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'ORF',
    city: 'Norfolk',
    state_code: 'VA',
    station_name: 'Norfolk Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'CMH',
    city: 'Columbus',
    state_code: 'OH',
    station_name: 'Glenn Columbus Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'HOU',
    city: 'Houston',
    state_code: 'TX',
    station_name: 'Hobby',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'LGB',
    city: 'Long Beach',
    state_code: 'CA',
    station_name: 'Long Beach/Daugherty Field',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'PLS',
    city: 'Providenciales',
    state_code: '',
    station_name: 'Providenciales Intl',
    country_name: 'Turks and Caicos',
    country_code: 'TC',
  },
  {
    station_code: 'HAV',
    city: 'Havana',
    state_code: '',
    station_name: 'Jose Marti Intl',
    country_name: 'Cuba',
    country_code: 'CU',
  },
  {
    station_code: 'PDX',
    city: 'Portland',
    state_code: 'OR',
    station_name: 'Portland Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'SMF',
    city: 'Sacramento',
    state_code: 'CA',
    station_name: 'Sacramento Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'MCI',
    city: 'Kansas City',
    state_code: 'MO',
    station_name: 'Kansas City Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'PWM',
    city: 'Portland',
    state_code: 'ME',
    station_name: 'Portland Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'JAN',
    city: 'Jackson',
    state_code: 'MS',
    station_name: 'Jackson-Evers Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'OAK',
    city: 'Oakland',
    state_code: 'CA',
    station_name: 'Metro Oakland Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'AUS',
    city: 'Austin',
    state_code: 'TX',
    station_name: 'Austin-Bergstrom Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'IAD',
    city: 'Washington D.C.',
    state_code: 'DC',
    station_name: 'Washington Dulles Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'FAT',
    city: 'Fresno',
    state_code: 'CA',
    station_name: 'Fresno Yosemite Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'CHS',
    city: 'Charleston',
    state_code: 'SC',
    station_name: 'Charleston Intl/AFB',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'STL',
    city: 'St. Louis',
    state_code: 'MO',
    station_name: 'St Louis Lambert Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'BLI',
    city: 'Bellingham',
    state_code: 'WA',
    station_name: 'Bellingham Intl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'NAS',
    city: 'Nassau',
    state_code: '',
    station_name: 'Lynden Pindling Intl',
    country_name: 'Bahamas',
    country_code: 'BS',
  },
  {
    station_code: 'MTJ',
    city: 'Montrose',
    state_code: 'CO',
    station_name: 'Montrose Regl',
    country_name: 'United States',
    country_code: 'US',
  },
  {
    station_code: 'GRR',
    city: 'Grand Rapids',
    state_code: 'MI',
    station_name: 'Gerald R Ford Intl',
    country_name: 'United States',
    country_code: 'US',
  },
];
