import { rest } from 'msw';
import {
  DeleteSubscriptionsRequest,
  DeleteSubscriptionsResponse,
  DeleteSubscriptionsResponseSubscriptionComponent,
  GetSubscriptionsSearchResponse,
  PutSubscriptionsRequest,
  PutSubscriptionsResponse,
  PutSubscriptionsResponseSubscriptionComponent,
} from '@crew/fsm-mobility-models-library';
import { mock_cities, mock_flight_details_dictionary } from './mock_data';

export const handlers = [
  // rest.get('/subscriptions/search', (req, res, ctx) => {
  // rest.get(/\/subscriptions\/search/, (req, res, ctx) => {
  rest.get('*/subscriptions/search', (req, res, ctx) => {
    const mockResponse: GetSubscriptionsSearchResponse = {
      subscriptions: [
        {
          flight_number: '3942',
          departure_date: '2024-11-13',
          departure_station: 'BLI',
          crew_member_id: 'x123456',
          channel_type: 'EMAIL',
          channel_address: 'jay.smith@wnco.com',
        },
        {
          flight_number: '1234',
          departure_date: '2024-12-09',
          departure_station: 'DAL',
          crew_member_id: 'x098765',
          channel_type: 'SMS',
          channel_address: '214-555-5555',
        },
        {
          flight_number: '5555',
          departure_date: '2024-11-09',
          departure_station: 'DEN',
          crew_member_id: 'x098765',
          channel_type: 'SMS',
          channel_address: '214-555-5555',
        },
        {
          flight_number: '987',
          departure_date: '2024-12-30',
          departure_station: 'HOU',
          crew_member_id: 'x098765',
          channel_type: 'SMS',
          channel_address: '214-555-5555',
        },
      ],
    };
    return res(ctx.json(mockResponse));
  }),

  rest.get('*/flightdetail/:flight_leg_key', (req, res, ctx) => {
    console.log('[Mock] (GET FlightDetail)', req);
    const { flight_leg_key } = req.params;
    if (typeof flight_leg_key !== 'string') {
      console.error(flight_leg_key);
      throw Error('Bad Flight Leg Key');
    }
    const mockResponse = mock_flight_details_dictionary[flight_leg_key];
    console.debug('[MSW] (Get FlightDetail) flight_get_key', flight_leg_key);
    console.debug('[MSW] (Get FlightDetail) mockRespose', mockResponse);
    return res(ctx.json(mockResponse));
  }),

  rest.put<PutSubscriptionsRequest>('*/subscriptions', (req, res, ctx) => {
    console.log(req);
    const req_data = req.body;
    const mockResponse: PutSubscriptionsResponse = new PutSubscriptionsResponse(
      [
        new PutSubscriptionsResponseSubscriptionComponent(
          req_data.flight_number === undefined ? '' : req_data.flight_number,
          req_data.departure_date === undefined ? '' : req_data.departure_date,
          req_data.departure_station === undefined
            ? ''
            : req_data.departure_station,
          req_data.crew_member_id === undefined ? '' : req_data.crew_member_id,
          req_data.channel_type === undefined ? '' : req_data.channel_type,
          req_data.channel_address === undefined ? '' : req_data.channel_address
        ),
      ]
    );

    return res(ctx.json(mockResponse));
  }),

  rest.delete<{ data: DeleteSubscriptionsRequest }>(
    '*/subscriptions',
    (req, res, ctx) => {
      console.log(req);
      const req_data = new URLSearchParams(req.url.search);

      const mockResponse: DeleteSubscriptionsResponse =
        new DeleteSubscriptionsResponse([
          new DeleteSubscriptionsResponseSubscriptionComponent(
            req_data.get('flight_number') ?? '',
            req_data.get('departure_date') ?? '',
            req_data.get('departure_station') ?? '',
            req_data.get('crew_member_id') ?? '',
            req_data.get('channel_type') ?? '',
            req_data.get('channel_address') ?? ''
          ),
        ]);
      return res(ctx.json(mockResponse));
    }
  ),

  rest.get('*/location', (req, res, ctx) => {
    return res(ctx.json(mock_cities));
  }),

  rest.get('*/flightnumbers', (req, res, ctx) => {
    return res(
      ctx.json({ flightNumbers: ['1234', '9999', '987', '31', '456'] })
    );
  }),
];
