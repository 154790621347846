import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './pages/App';
import { EnvironmentData } from './shared';
import reportWebVitals from './reportWebVitals';

const environmentData = EnvironmentData.newEnvironmentData({
  ENVIRONMENT_NAME: process.env.REACT_APP_ENVIRONMENT_NAME,
  NODE_ENV: process.env.NODE_ENV,
  API_URL: process.env.REACT_APP_API_URL,
  MOCK_API: process.env.REACT_APP_MOCK_API === 'true',
  FAKE_DATA: process.env.REACT_APP_FAKE_DATA === 'true',
  USE_AUTH: process.env.REACT_APP_USE_AUTH === 'true',
  READ_AUTH_TOKEN: process.env.REACT_APP_READ_AUTH_TOKEN === 'true',
  PING_CLIENT_ID: process.env.REACT_APP_PING_CLIENT_ID,
  PING_CLIENT_SECRET: process.env.REACT_APP_PING_CLIENT_SECRET,
  PING_OPEN_ID_CONNECT_URL: process.env.REACT_APP_PING_OPEN_ID_CONNECT_URL,
  PING_REDIRECT_URI: `https://${window.location.hostname}`,
  PING_SCOPE: process.env.REACT_APP_PING_SCOPE,
  PING_AUDIENCE: process.env.REACT_APP_PING_AUDIENCE,
  PING_ALLOWED_GROUPS: process.env.REACT_APP_PING_ALLOWED_GROUPS?.split(' '),
  FAKE_USER_ID: process.env.REACT_APP_FAKE_USER_ID,
  FAKE_USER_EMAIL: process.env.REACT_APP_FAKE_USER_EMAIL,
  ALLOW_USER_FLIGHTS: process.env.REACT_APP_ALLOW_USER_FLIGHTS === 'true',
  ALLOW_USER_EMAIL: process.env.REACT_APP_ALLOW_USER_EMAIL === 'true',
  PING_LOGOUT_URL: process.env.REACT_APP_PING_LOGOUT_URL,
});

console.debug(`[Index] Environment Name: ${environmentData.ENVIRONMENT_NAME}`);
console.debug(`[Index] NODE ENV: ${environmentData.NODE_ENV}`);
console.debug(`[Index] API URL: ${environmentData.API_URL}`);
console.debug(`[Index] Mock API: ${environmentData.MOCK_API}`);
console.debug(`[Index] Fake Data: ${environmentData.FAKE_DATA}`);
console.debug(`[Index] Using Auth: ${environmentData.USE_AUTH}`);
console.debug(`[Index] Reading Auth Token: ${environmentData.READ_AUTH_TOKEN}`);
console.debug(`[Index] Ping Client ID: ${environmentData.PING_CLIENT_ID}`);
console.debug(
  `[Index] Ping Client Secret: ${environmentData.PING_CLIENT_SECRET}`
);
console.debug(
  `[Index] Ping Open Id Connect Url: ${environmentData.PING_OPEN_ID_CONNECT_URL}`
);
console.debug(
  `[Index] Ping Redirect URI: ${environmentData.PING_REDIRECT_URI}`
);
console.debug(`[Index] Ping Scope: ${environmentData.PING_SCOPE}`);
console.debug(`[Index] Ping Audience: ${environmentData.PING_AUDIENCE}`);
console.debug(
  `[Index] Ping Allowed Groups: ${environmentData.PING_ALLOWED_GROUPS}`
);
console.debug(`[Index] Fake User ID: ${environmentData.FAKE_USER_ID}`);
console.debug(`[Index] Fake User Email: ${environmentData.FAKE_USER_EMAIL}`);
console.debug(
  `[Index] Allow User Flights: ${environmentData.ALLOW_USER_FLIGHTS}`
);
console.debug(`[Index] Allow User Email: ${environmentData.ALLOW_USER_EMAIL}`);

if (environmentData.MOCK_API) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start();
}

const container = document.getElementById('root');
if (container !== null) {
  createRoot(container).render(
    // <React.StrictMode>
    <App environmentData={environmentData} />
    // </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
