import { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Checkbox } from '@swacl/core';

import { CleanRow, WrapperBase } from '../shared';
import { IStyledProps, NotificationType, mediaQueryMap } from '../../shared';
import { ViewNotificationPreferenceData } from './view-notifications-data.model';

const NotificationPreferencesLabel = styled(({ className = '' }) => (
  <label className={className}>NOTIFICATION PREFERENCES</label>
))`
  width: max-content;
`;

const ViewNotificationCheckboxWrapperBase: FC<
  PropsWithChildren<IStyledProps>
> = ({ children, className = '' }) => {
  return (
    <div
      className={`grid__column-4--sm grid__column-6--md grid__column-4 ${className}`}
    >
      {children}
    </div>
  );
};

const ViewNotificationCheckboxWrapper = styled(
  ViewNotificationCheckboxWrapperBase
)`
  margin-top: 12px;
`;

const ViewNotificationsButtonsWrapper = styled(WrapperBase)`
  ${mediaQueryMap['lg']} {
    margin-left: 24px;
  }
`;

const ViewNotificationButtonsRow = styled(
  ({
    className = '',
    UpdateButton,
    DeleteButton,
  }: {
    className?: string;
    UpdateButton: FC;
    DeleteButton: FC;
  }) => {
    return (
      <CleanRow className={className}>
        <ViewNotificationsButtonsWrapper>
          <DeleteButton />
        </ViewNotificationsButtonsWrapper>
        <ViewNotificationsButtonsWrapper>
          <UpdateButton />
        </ViewNotificationsButtonsWrapper>
      </CleanRow>
    );
  }
)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${mediaQueryMap['md']} {
    margin-top: 20px;
  }
  ${mediaQueryMap['lg']} {
    justify-content: end;
  }
`;

const ViewNotificationButtons = styled(
  ({
    className = '',
    UpdateButton,
    DeleteButton,
  }: {
    className?: string;
    UpdateButton: FC;
    DeleteButton: FC;
  }) => {
    return (
      <div
        className={`grid__column-12--sm grid__column-12--md grid__column-6 ${className}`}
      >
        <ViewNotificationButtonsRow
          UpdateButton={UpdateButton}
          DeleteButton={DeleteButton}
        />
      </div>
    );
  }
)`
  display: flex;
  align-items: center;
`;

interface Props {
  preferences: ViewNotificationPreferenceData[];
  UpdateButton: FC;
  DeleteButton: FC;
}

const ViewNotificationPreferencesBase: FC<IStyledProps & Props> = ({
  className = '',
  preferences,
  UpdateButton,
  DeleteButton,
}) => {
  const hasPreference = (preferenceType: NotificationType) => {
    return (
      preferences.filter((pref) => pref.notificationType === preferenceType)
        .length !== 0
    );
  };

  return (
    <CleanRow className={className}>
      <div className={'grid__column-12--sm grid__column-12--md grid__column-6'}>
        <CleanRow>
          <NotificationPreferencesLabel />
        </CleanRow>
        <CleanRow>
          <ViewNotificationCheckboxWrapper>
            <Checkbox
              id={'1'}
              label={'Text Msg'}
              disabled={true}
              checked={hasPreference(NotificationType.SMS)}
            />
          </ViewNotificationCheckboxWrapper>
          <ViewNotificationCheckboxWrapper>
            <Checkbox
              id={'2'}
              label={'Email'}
              disabled={true}
              checked={hasPreference(NotificationType.EMAIL)}
            />
          </ViewNotificationCheckboxWrapper>
        </CleanRow>
      </div>
      <ViewNotificationButtons
        UpdateButton={UpdateButton}
        DeleteButton={DeleteButton}
      />
    </CleanRow>
  );
};

export const ViewNotificationPreferences = styled(
  ViewNotificationPreferencesBase
)`
  margin-top: 16px;
`;
