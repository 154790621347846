import { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  Dropdown,
  MessageType,
  NormalizedDropdown,
  NormalizedDropdownList,
} from '@swacl/core';

import { StyledFormElementWrapper } from '../shared';
import { backendApiService, EnvironmentContext } from '../../shared';

import { GetFlightNumbersResponse } from '../../shared/models/responses';

interface Props {
  disabled: boolean;
  updateFlightNumber: (input: string | null) => void;
  attachReset: (callback: () => void) => void;
  date: Date | null;
  departureCity: string | null;
  arrivalCity: string | null;
}

export const FlightNumberSelect: FC<Props> = ({
  disabled,
  updateFlightNumber,
  attachReset,
  date,
  departureCity,
  arrivalCity,
}) => {
  const environmentData = useContext(EnvironmentContext);
  const defaultMessageType = 'info';
  const defaultMessageText =
    'Provide stations and date to search applicable flight numbers';

  const [flightNumber, setFlightNumber] = useState<NormalizedDropdown | null>(
    null
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [options, setOptions] = useState<NormalizedDropdown[]>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [messageType, setMessageType] =
    useState<MessageType>(defaultMessageType);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [messageText, setMessageText] = useState<string>(defaultMessageText);

  const onSelectChange = (
    option: NormalizedDropdownList | NormalizedDropdown,
    id: string
  ) => {
    if (Array.isArray(option)) return;
    setFlightNumber(option);
    updateFlightNumber(option.value);
  };

  const resetFlightNumber = useCallback(() => {
    setFlightNumber(null);
    updateFlightNumber(null);
    setMessageType(defaultMessageType);
    setMessageText(defaultMessageText);
  }, [updateFlightNumber]);

  const resetFlightNumberOptions = useCallback(
    (date: Date, departureCity: string, arrivalCity: string) => {
      setMessageType(defaultMessageType);
      setMessageText('Loading flights...');
      backendApiService
        .get<GetFlightNumbersResponse>('/flightnumbers', {
          params: {
            departureCity: departureCity,
            arrivalCity: arrivalCity,
            departureDate:
              `${date.getFullYear()}-` +
              `${('0' + (date.getMonth() + 1)).slice(-2)}-` +
              `${('0' + date.getDate()).slice(-2)}`,
          },
        })
        .then((res) => {
          console.debug(
            '[FlightNumberSelect] (resetFlightNumberOptions) response',
            res
          );
          setOptions(
            res.data.flightNumbers
              .map((flightNumber: string) => ({
                label: flightNumber,
                value: flightNumber,
              }))
              .sort((a, b) => (parseInt(a.value) < parseInt(b.value) ? -1 : 1))
          );
          if (res.data.flightNumbers.length) {
            setMessageText(defaultMessageType);
            setMessageText(`${res.data.flightNumbers.length} flights found`);
          } else {
            setMessageType('warning');
            setMessageText('No flights found');
          }
        })
        .catch((error) => {
          console.error(error);
          setMessageType('error');
          setMessageText('Error loading flights');
        });
    },
    []
  );

  useEffect(() => {
    if (date && departureCity && arrivalCity) {
      setFlightNumber(null);
      resetFlightNumberOptions(date, departureCity, arrivalCity);
    }
  }, [date, departureCity, arrivalCity, resetFlightNumberOptions]);

  useEffect(() => {
    if (attachReset) attachReset(resetFlightNumber);
  }, [attachReset, resetFlightNumber]);

  return (
    <StyledFormElementWrapper>
      <Dropdown
        id={'flight-number'}
        label={'Flight Number'}
        allowSearch={true}
        isSearchAndCreateType={environmentData.ALLOW_USER_FLIGHTS}
        onChange={onSelectChange}
        options={options ?? []}
        disabled={disabled}
        messageType={messageType}
        messageText={messageText}
        value={flightNumber}
      />
    </StyledFormElementWrapper>
  );
};
