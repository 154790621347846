import { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { useMatchingMedia } from '../../shared';

interface Props {
  className?: string;
}

export const FormElementWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  className = '',
}) => {
  const matchesMD = useMatchingMedia('xl');
  const getColumns = () => {
    if (matchesMD) {
      return 'grid__column-12--sm grid__column-12--md grid__column-6';
    } else {
      return 'grid__column-12--sm grid__column-12--md grid__column-12';
    }
  };
  return <div className={getColumns() + ` ${className}`}>{children}</div>;
};

export const StyledFormElementWrapper = styled(FormElementWrapper)`
  margin-top: 16px;
  div.react-select__value-container {
    flex-wrap: nowrap !important;
  }
`;

export const StyledDatePickerWrapper = styled(StyledFormElementWrapper)`
  [data-testid^='calendar-wrapper'] {
    top: 50px;
  }
`;
