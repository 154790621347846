import { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';

import { mediaQueryMap } from '../../shared/utilities';

interface Props {
  className?: string; // This can be pulled out as a typical interface for styles
}

export const Grid: FC<PropsWithChildren<Props>> = ({
  children,
  className = '',
}) => {
  return <div className={`grid ${className}`}>{children}</div>;
};

export const CleanGrid = styled(Grid)`
  margin: 0;
`;

export const Row: FC<PropsWithChildren<Props>> = ({
  children,
  className = '',
}) => {
  return <div className={`grid__row ${className}`}>{children}</div>;
};

export const CleanRow = styled(Row)`
  grid-row-gap: 0;
  grid-column-gap: 0;
`;

export const FormRow = styled(Row)`
  grid-row-gap: 0;
  grid-column-gap: 0;
  ${mediaQueryMap['xl']} {
    grid-column-gap: 48px;
  }
`;
