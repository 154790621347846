import { NotificationType } from '..';

export class EditNotificationFormData {
  constructor(
    public crewMemberId: string,
    public flightNumber: string,
    public departureDate: Date,
    public departureStation: string,
    public arrivalStation: string,
    public notificationPreferences: EditNotificationFormPreferenceData[]
  ) {}
}

export class EditNotificationFormPreferenceData {
  constructor(
    public notificationType: NotificationType,
    public notificationAddress: string
  ) {}

  static equal(
    preferenceA: EditNotificationFormPreferenceData,
    preferenceB: EditNotificationFormPreferenceData
  ): boolean {
    return (
      preferenceA.notificationType === preferenceB.notificationType &&
      preferenceA.notificationAddress === preferenceB.notificationAddress
    );
  }
}
