import {
  FC,
  useState,
  ChangeEvent,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { Checkbox, Input, MessageType } from '@swacl/core';
import styled from '@emotion/styled';
import { CleanGrid, CleanRow, WrapperBase } from '.';
import {
  AuthContext,
  EditNotificationFormPreferenceData,
  EnvironmentContext,
  IStyledProps,
  mediaQueryMap,
  NotificationType,
} from '../../shared';

const PreferencesHeaderBase: FC<IStyledProps & { columns: number[] }> = ({
  className = '',
  columns,
}) => {
  return (
    <WrapperBase className={className} columns={columns}>
      <label>Notification Preferences</label>
    </WrapperBase>
  );
};

const PreferencesHeader = styled(PreferencesHeaderBase)`
  margin-top: 16px;
  text-align: left;
`;

const NotificationCheckboxWrapper = styled(WrapperBase)`
  margin-top: 12px;
`;

const NotificationPreferenceElementWrapper = styled(WrapperBase)`
  padding: 8px 16px 16px;
  @media (prefers-color-scheme: dark) {
    background-color: var(--colors-gray-7);
  }
  @media (prefers-color-scheme: light) {
    background-color: var(--colors-gray-2);
  }
`;

const PreferenceInputRowWrapper = styled(CleanRow)`
  margin-top: 12px;
  ${mediaQueryMap['lg']} {
    margin: 16px 0 0 20px;
  }
`;

class inputMessage {
  constructor(public msgType: MessageType, public msgText: string) {}
}

interface Props {
  initialNotificationPrefences?: EditNotificationFormPreferenceData[];
  addNotificationPreference: (
    input: EditNotificationFormPreferenceData
  ) => void;
  removeNotificationPreference: (notifcationType: NotificationType) => void;
  addNotificationSelectionType: (input: NotificationType) => void;
  removeNotificationSelectionType: (input: NotificationType) => void;
  isModal?: boolean;
  attachRemovePreferences?: (callback: () => void) => void;
}

export const EditNotificationPreferences: FC<Props> = ({
  initialNotificationPrefences,
  addNotificationPreference,
  removeNotificationPreference,
  addNotificationSelectionType,
  removeNotificationSelectionType,
  isModal = false,
  attachRemovePreferences,
}) => {
  const authData = useContext(AuthContext);
  const envData = useContext(EnvironmentContext);

  /**
   *
   * @param preferences The preferences to search through.
   * @param preferenceType The preference type to search for.
   * @returns The notification address for the preference in preferences
   * that matches the preference type, or the empty string if no
   * preference matches the preference type.
   */
  const initialPreferenceAddress = (
    preferences: EditNotificationFormPreferenceData[],
    preferenceType: NotificationType
  ) => {
    const typePreference = preferences.filter(
      (pref) => pref.notificationType === preferenceType
    );
    return typePreference.length > 0
      ? typePreference[0].notificationAddress
      : '';
  };

  const initialPhoneNumber =
    initialNotificationPrefences !== undefined
      ? initialPreferenceAddress(
          initialNotificationPrefences,
          NotificationType.SMS
        )
      : '';
  const initialEmail =
    initialNotificationPrefences !== undefined
      ? initialPreferenceAddress(
          initialNotificationPrefences,
          NotificationType.EMAIL
        )
      : '';

  const [textChecked, setTextChecked] = useState<boolean>(
    initialPhoneNumber !== ''
  );
  const [emailChecked, setEmailChecked] = useState<boolean>(
    initialEmail !== ''
  );
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [phoneNumber, setPhoneNumber] = useState<string>(initialPhoneNumber);
  const [email, setEmail] = useState<string>(initialEmail);
  /* eslint-enable*/

  useEffect(() => {
    if (
      initialEmail === '' &&
      authData.userEmail &&
      !envData.ALLOW_USER_EMAIL
    ) {
      setEmail(authData.userEmail);
    }
  }, [initialEmail, authData, envData]);

  // Messages are displayed on the Input components.
  const [phoneNumberMessage, setPhoneNumberMessage] = useState<inputMessage>({
    msgType: 'none',
    msgText: '',
  });

  const [emailInputMessage, setEmailInputMessage] = useState<inputMessage>({
    msgType: envData.ALLOW_USER_EMAIL ? 'none' : 'info',
    msgText: envData.ALLOW_USER_EMAIL
      ? ''
      : 'Your SWA email is auto-filled for you.',
  });

  const resetPhoneMessage = () =>
    setPhoneNumberMessage({
      msgType: 'none',
      msgText: '',
    });

  const resetEmailMessage = () => {
    setEmailInputMessage({
      msgType: 'none',
      msgText: '',
    });
  };

  // set id to distinguish components in modal
  const textCheckboxId = !isModal ? 'text-checkbox' : 'modal-text-checkbox';
  const emailCheckboxId = !isModal ? 'email-checkbox' : 'modal-email-checkbox';

  const onCheckChange = (checkedState: boolean, id: string) => {
    console.debug('[EditNotificationPreferences] (onCheckChange) input');
    console.debug({ id: id, checked: checkedState });

    if (id === textCheckboxId) {
      if (!checkedState) {
        // remove preference when de-checked
        removeNotificationPreference(NotificationType.SMS);

        removeNotificationSelectionType(NotificationType.SMS);
        resetPhoneMessage();
        setPhoneNumber('');
      } else {
        addNotificationSelectionType(NotificationType.SMS);
      }
      setTextChecked(checkedState);
    } else {
      setEmailChecked(checkedState);
      if (!checkedState) {
        // remove preference when de-checked
        removeNotificationPreference(NotificationType.EMAIL);
        removeNotificationSelectionType(NotificationType.EMAIL);

        // if user can type the email then reset the field when they uncheck the email box
        if (envData.ALLOW_USER_EMAIL) {
          resetEmailMessage();
          setEmail('');
        }
      } else {
        addNotificationSelectionType(NotificationType.EMAIL);
        // this is OK because the user cannot interact with the email field
        if (!envData.ALLOW_USER_EMAIL) {
          addNotificationPreference({
            notificationType: NotificationType.EMAIL,
            notificationAddress: email,
          });
        }
      }
    }
  };

  const validEmailRegex = /^\w+([.-]?\w+)*@wnco.com$/;

  const validateEmail = (email: string): boolean => {
    if (validEmailRegex.test(email)) {
      resetEmailMessage();
      return true;
    } else {
      setEmailInputMessage({
        msgType: 'error',
        msgText: 'Enter valid wnco email',
      });
      return false;
    }
  };

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    // validate email

    setEmail(newEmail);
    removeNotificationPreference(NotificationType.EMAIL);
    if (validateEmail(newEmail)) {
      addNotificationPreference({
        notificationType: NotificationType.EMAIL,
        notificationAddress: newEmail,
      });
    }
  };

  /**
   *
   * @param number The input of the number field
   * @returns True if the `number` has exactly 10 digits in it, false otherwise.
   * This ignores all other characters, so a user can enter the number in
   * any format they prefer.
   */
  const validatePhoneNumber = (number: string) => {
    if (number.length !== 10) {
      setPhoneNumberMessage({
        msgType: 'error',
        msgText: 'Enter a 10 digit phone number',
      });
      return false;
    } else {
      resetPhoneMessage();
      return true;
    }
  };

  const onPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newNumber = e.target.value.replace(/\D/g, '').substring(0, 10);
    if (
      newNumber.length === 10 &&
      phoneNumber.replace(/\D/g, '').substring(0, 10).length === 10
    ) {
      return;
    }
    const regNumber = newNumber.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    console.log(newNumber);
    console.log(regNumber);
    if (!regNumber || newNumber.length === 0) {
      setPhoneNumber('');
      removeNotificationPreference(NotificationType.SMS);
      return;
    }
    if (newNumber.length <= 3) {
      setPhoneNumber(`${regNumber[1]}`);
    } else if (newNumber.length <= 6) {
      setPhoneNumber(`${regNumber[1]}-${regNumber[2]}`);
    } else {
      setPhoneNumber(`${regNumber[1]}-${regNumber[2]}-${regNumber[3]}`);
    }

    if (validatePhoneNumber(newNumber)) {
      addNotificationPreference({
        notificationType: NotificationType.SMS,
        notificationAddress: newNumber,
      });
    } else {
      removeNotificationPreference(NotificationType.SMS);
    }
  };

  const removePreferences = useCallback(() => {
    setPhoneNumber('');
    if (envData.ALLOW_USER_EMAIL) {
      setEmail(''); // reset the email only if the user can enter their email (in prod the email is uneditable and prepopulated)
    }
    removeNotificationPreference(NotificationType.SMS);
    removeNotificationPreference(NotificationType.EMAIL);
    removeNotificationSelectionType(NotificationType.SMS);
    removeNotificationSelectionType(NotificationType.EMAIL);
    setTextChecked(false);
    setEmailChecked(false);
  }, [
    setPhoneNumber,
    removeNotificationPreference,
    removeNotificationSelectionType,
    envData.ALLOW_USER_EMAIL,
  ]);

  useEffect(() => {
    if (attachRemovePreferences) attachRemovePreferences(removePreferences);
  }, [attachRemovePreferences, removePreferences]);

  const checkboxColumns = !isModal ? [4, 4, 2] : [4, 6, 4];
  const headerColumns = !isModal ? [12, 12, 6] : [12, 12, 12];
  const preferenceColumns = !isModal ? [12, 12, 6] : [12, 12, 12];

  return (
    <CleanGrid>
      <CleanRow>
        <PreferencesHeader columns={headerColumns} />
      </CleanRow>
      <CleanRow>
        <NotificationCheckboxWrapper columns={checkboxColumns}>
          <Checkbox
            id={textCheckboxId}
            checked={textChecked}
            label={'Text Msg'}
            onChange={onCheckChange}
          />
        </NotificationCheckboxWrapper>
        <NotificationCheckboxWrapper columns={checkboxColumns}>
          <Checkbox
            id={emailCheckboxId}
            checked={emailChecked}
            label={'Email'}
            onChange={onCheckChange}
          />
        </NotificationCheckboxWrapper>
      </CleanRow>
      <PreferenceInputRowWrapper>
        {/* Phone Number Input */}
        {textChecked ? (
          <NotificationPreferenceElementWrapper columns={preferenceColumns}>
            <Input
              id={'phone-number-input'}
              label={'Phone Number'}
              type={'tel'}
              value={phoneNumber}
              onChange={onPhoneNumberChange}
              messageType={phoneNumberMessage.msgType}
              messageText={phoneNumberMessage.msgText}
            ></Input>
          </NotificationPreferenceElementWrapper>
        ) : null}

        {/* Email Input */}

        {emailChecked ? (
          <NotificationPreferenceElementWrapper columns={preferenceColumns}>
            <Input
              id={'email-input'}
              label={'Email'}
              type={'email'}
              disabled={!envData.ALLOW_USER_EMAIL}
              value={email}
              // Required because Input requires onChange
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChange={onEmailChange}
              messageType={emailInputMessage.msgType}
              messageText={emailInputMessage.msgText}
            ></Input>
          </NotificationPreferenceElementWrapper>
        ) : null}
      </PreferenceInputRowWrapper>
    </CleanGrid>
  );
};
