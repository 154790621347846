import { NonFunctionProperties } from '@crew/fsm-mobility-models-library';
import { createContext } from 'react';

export class AuthContextData {
  constructor(
    public authed: boolean,
    public auth_flow_complete: boolean,
    public userId?: string,
    public userEmail?: string,
    public authToken?: string,
    public setAuth?: (obj: NonFunctionProperties<AuthContextData>) => void
  ) {}

  static newAuthContextData(
    obj: NonFunctionProperties<AuthContextData>,
    setAuth?: (obj: NonFunctionProperties<AuthContextData>) => void
  ): AuthContextData {
    return new AuthContextData(
      obj.authed,
      obj.auth_flow_complete,
      obj.userId,
      obj.userEmail,
      obj.authToken,
      setAuth
    );
  }
}

export const AuthContext = createContext<AuthContextData>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  new AuthContextData(false, false)
);
