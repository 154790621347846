import styled from '@emotion/styled';
import { FC } from 'react';
import { CleanRow } from './shared/CleanGrid';
import { mediaQueryMap } from '../shared';

import { IStyledProps } from '../shared';

const HeaderBase: FC<IStyledProps> = ({ className = '' }) => {
  return (
    <CleanRow>
      <h2
        className={`grid__column-12--sm grid__column-12--md grid__column-12 ${className}`}
      >
        Flight Status Notification
      </h2>
    </CleanRow>
  );
};

export const Header = styled(HeaderBase)`
  margin: 16px 0 0 16px;

  ${mediaQueryMap['lg']} {
    margin: 32px 0 0 24px;
  } ;
`;
