import styled from '@emotion/styled';
import { Modal } from '@swacl/core';
import { FC } from 'react';
import { CleanGrid, CleanRow } from '..';
import { useMatchingMedia } from '../../shared';

const UnauthorizedModalHeader = styled(
  ({ className = '' }: { className?: string }) => {
    return (
      <CleanRow
        className={`grid__column-12--sm grid__column-12--md grid__column-12 ${className}`}
      >
        <h2>Unauthorized!</h2>
      </CleanRow>
    );
  }
)`
  display: flex;
  justify-content: center;
`;

const UnauthorizedModalBodyText = styled(
  ({ className = '' }: { className?: string }) => {
    return (
      <CleanRow
        className={`grid__column-12--sm grid__column-12--md grid__column-12 ${className}`}
      >
        <p>
          You are not authorized to visit this site. If you believe this is an
          error, contact your security administrator.
        </p>
      </CleanRow>
    );
  }
)`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`;

interface Props {
  authorize: () => void;
}

export const UnauthorizedModal: FC<Props> = ({ authorize }) => {
  const modalWidth = useMatchingMedia('lg') ? 471 : 368;
  const bodyContent = (
    <CleanGrid>
      <UnauthorizedModalHeader />
      <UnauthorizedModalBodyText />
    </CleanGrid>
  );
  return (
    <Modal
      open={true}
      bodyContent={bodyContent}
      width={modalWidth}
      blockClose={true}
      showClose={false}
    />
  );
};
