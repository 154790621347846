import { FC, useCallback, useEffect, useState } from 'react';
import { SWAThemeProvider } from '@swacl/themes';
import { DateTimeProvider, LoadingScreen } from '@swacl/core';
import { TopNav } from '@swacl/navigation';
import BaseAdapterDateFns from '@date-io/date-fns';
import { OAuthBuilder, TokenResponse } from '@ef/oauth-js-client';
import { CookiesProvider, useCookies } from 'react-cookie';

import {
  AuthContext,
  oauthListenerFactory,
  validatePingEnvironment,
  EnvironmentContext,
  EnvironmentData,
  useMatchingMedia,
  parseJwt,
  LocationStationLookup,
} from '../../shared';

import {
  CleanGrid,
  CleanRow,
  CreateNotificationForm,
  Header,
  ViewNotifications,
  UnauthorizedModal,
  LogoutButton,
} from '../../components';
import {
  Location,
  NonFunctionProperties,
} from '@crew/fsm-mobility-models-library';
import { AuthContextData } from '../../shared/services/auth-context';
import { backendApiService } from '../../shared/services/http-service';
import assert from 'assert';

interface Props {
  environmentData: EnvironmentData;
}

export const App: FC<Props> = ({ environmentData }) => {
  const matchesMD = useMatchingMedia('md');
  const TOKEN_RESPONSE_COOKIE_NAME = 'tokenResponseCookie';
  const HOURS_PING_COOKIE_LASTS = 2;
  const REDIRECTED_QUERY_PARAM_INDICATOR = 'code'; // this is the query param that is set when we are redirected back from ping

  const [cookies, setCookie, removeCookie] = useCookies([
    TOKEN_RESPONSE_COOKIE_NAME,
  ]);
  const [authData, setAuthData] = useState<
    NonFunctionProperties<AuthContextData>
  >({
    authed: false,
    auth_flow_complete: false,
  });

  const authContext: AuthContextData = {
    ...authData,
    setAuth: setAuthData,
  };

  const setUnauthed = () =>
    setAuthData({
      authed: false,
      auth_flow_complete: true,
    });

  // method for handling the user info retrieval and setting authData
  const setAuthDataFromToken = useCallback(
    async (authToken: string): Promise<boolean> => {
      // global setting of axios headers
      backendApiService.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${authToken}`;
      try {
        console.debug(
          '[App] (OAuth) (setAuthDataFromToken) <axios get user info>'
        );
        // retrieves openId user info
        const userInfoResponse = await backendApiService.get<{
          email: string;
          sub: string;
        }>(`${environmentData.PING_OPEN_ID_CONNECT_URL}/idp/userinfo.openid`);
        console.debug(
          '[App] (OAuth) (setAuthDataFromToken) <axios get user info> Success',
          userInfoResponse
        );

        const parsedJwt = parseJwt(authToken);
        const groupsIntersect = parsedJwt.groups.filter((val) =>
          (environmentData.PING_ALLOWED_GROUPS ?? []).includes(val)
        );

        if (groupsIntersect.length > 0) {
          // updates authData when successful
          // ViewNotifications will automatically call search
          // when authData is updated
          setAuthData({
            authed: true,
            auth_flow_complete: true,
            userId: userInfoResponse.data.sub,
            userEmail: userInfoResponse.data.email,
            authToken: authToken,
          });
          const queryParams = new URLSearchParams(window.location.search);
          if (queryParams.has(REDIRECTED_QUERY_PARAM_INDICATOR)) {
            queryParams.delete(REDIRECTED_QUERY_PARAM_INDICATOR);
            window.history.replaceState(
              {},
              document.title,
              window.location.toString().replace(window.location.search, '')
            );
          }
        } else {
          setUnauthed();
          console.debug('User is not authorized for this application');
        }
      } catch (err) {
        console.error(
          '[App] (OAuth) (setAuthDataFromToken) <axios get user info> Failed'
        );
        console.error(err);
        setUnauthed();
        return false;
      }
      return true;
    },
    [environmentData]
  );

  const validPingEnv = validatePingEnvironment(environmentData);
  // The main authorization flow
  const authorizeMain = useCallback(async () => {
    let cookieAuthValid = false;
    // if token response cookie exists, then read it and set auth data, otherwise, do auth flow
    if (cookies.tokenResponseCookie) {
      try {
        const tokenResponse =
          cookies.tokenResponseCookie as Partial<TokenResponse>;
        if (tokenResponse.accessToken) {
          cookieAuthValid = await setAuthDataFromToken(
            tokenResponse.accessToken
          );
        } else {
          throw Error('No access token present in cookie');
        }
      } catch (err) {
        console.debug('[App] Unable to read token response cookie');
        console.error(err);
      }
    }
    if (!cookieAuthValid) {
      // if not using auth
      if (!environmentData.USE_AUTH) {
        console.debug('[App] (OAuth) Auth disabled');
        setAuthData({
          authed: true,
          auth_flow_complete: true,
          userId: environmentData.FAKE_USER_ID ?? '',
          userEmail: environmentData.FAKE_USER_EMAIL ?? '',
          authToken: 'N/A',
        });
        return;
      }

      if (environmentData.READ_AUTH_TOKEN) {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
          console.error(
            '[App] No auth token is set in localstorage "authToken"!'
          );
          setUnauthed();
          return;
        }
        // save token response in cookie
        const tokenResponse: Partial<TokenResponse> = {
          accessToken: authToken,
        };
        try {
          setCookie(TOKEN_RESPONSE_COOKIE_NAME, tokenResponse);
          console.debug(`[App] (Cookies) Cookie has been set`);
        } catch (err) {
          console.error(`[App] (Cookies) Unable to set cookie ${err}`);
        }
        await setAuthDataFromToken(authToken);
        return;
      }

      // if Ping environment is invalid
      if (!validPingEnv) {
        setUnauthed();
        return;
      }
      assert(
        environmentData.PING_CLIENT_ID &&
          environmentData.PING_OPEN_ID_CONNECT_URL
      );
      const oauth = new OAuthBuilder()
        .withFetchRequestor()
        .withNoHashQueryStringUtils()
        .build(
          environmentData.PING_CLIENT_ID,
          environmentData.PING_CLIENT_SECRET,
          environmentData.PING_OPEN_ID_CONNECT_URL
        );

      // Using Auth and Ping is valid
      // begin by setting up methods

      // actual authorization flow
      const authFlow = async () => {
        // assertion from valid Ping environment
        assert(environmentData.PING_REDIRECT_URI && environmentData.PING_SCOPE);
        const redirectURI = environmentData.PING_REDIRECT_URI;
        const scope = environmentData.PING_SCOPE;

        // method to make the authorization redirect
        const authorize = async (stage: string) => {
          console.debug(
            `[App] (OAuth) Begginning authorization for stage ${stage}`
          );
          await oauth.fetchAuthorizationServiceConfiguration();
          oauth.authorize(redirectURI, scope);
        };

        const handleAuth = async (tokenResponse: TokenResponse) => {
          // save token response in cookie
          try {
            setCookie(
              TOKEN_RESPONSE_COOKIE_NAME,
              {
                accessToken: tokenResponse.accessToken,
              },
              {
                maxAge: HOURS_PING_COOKIE_LASTS * 60 * 60, // the cookie will be deleted after this many seconds. This is redundant. If the auth fails when using the cookie, we will reauth. The only reason why we set this maxAge to automatically delete the cookie is so that we do not make an unauthorized call.
              }
            );
            console.debug(`[App] (Cookies) Cookie has been set`);
          } catch (err) {
            console.error(`[App] (Cookies) Unable to set cookie ${err}`);
          }
          // handle first token response
          await setAuthDataFromToken(tokenResponse.accessToken);
        };

        // if there is an code in the query params, this means the user was just redirected back
        const queryParams = new URLSearchParams(window.location.search);
        const codeQueryStringParam = queryParams.get(
          REDIRECTED_QUERY_PARAM_INDICATOR
        );
        if (codeQueryStringParam !== null) {
          console.debug(
            '[App] (OAuth) Outstanding authorization needs resolution'
          );
          console.debug('[App] (OAuth) Setting stage 1 listener');
          // set initial listener
          oauth.setAuthorizationListener(
            oauthListenerFactory({
              oauth: oauth,
              errorHandler: setUnauthed,
              codeExchangeExternalParams: {
                redirectUri: redirectURI,
                audience: environmentData.PING_AUDIENCE,
              },
              successfulExchangeHandler: handleAuth,
            })
          );

          // this calls the listener to complete the auth flow
          oauth.checkForAuthorizationResponse();
        } else {
          // no outstanding authorization
          console.debug('[App] (OAuth) Beginning auth flow');
          try {
            await authorize('1');
          } catch (err) {
            console.error('[App] (OAuth) FAILED Authorization');
            console.error(err);
            setUnauthed();
          }
        }
      };
      authFlow();
    }
  }, [
    environmentData,
    validPingEnv,
    setAuthDataFromToken,
    setCookie,
    cookies.tokenResponseCookie,
  ]);

  const reauthorize = async () => {
    removeCookie(TOKEN_RESPONSE_COOKIE_NAME);
    setUnauthed();
    cookies.tokenResponseCookie = null;
    await authorizeMain();
  };

  const logout = async () => {
    setIsLoggingOut(true);
    cookies.tokenResponseCookie = null;
    removeCookie(TOKEN_RESPONSE_COOKIE_NAME);
    setUnauthed();
    if (environmentData.PING_LOGOUT_URL) {
      window.location.href = environmentData.PING_LOGOUT_URL;
    }
    // with some browsers after the user logs out, they can press the back arrow to navigate back to the app, which will still be running. To prevent any user data from being exposed, after 3 seconds, we will rerun the authorizer main.
    setTimeout(async function () {
      setIsLoggingOut(false);
      await authorizeMain();
    }, 3000);
  };

  // Initiate authorization
  useEffect(() => {
    authorizeMain();
    // eslint-disable-next-line
  }, []);

  /**
   * Allows calling the search function in ViewNotifications
   * from other components.
   *
   * - callSearch will reference the function we want to call,
   * after the initial render of ViewNotifications.
   *
   * - attachCallSearch is passed down to ViewNotifications,
   * where it is called on render to attach ViewNotifications
   * search function to App's callSearch function.
   *
   * - handleCallSearch provides an abstraction from callSearch,
   * allowing us to provide a method to other components that will
   * remain useful even after callSearch's reference is changed.
   *
   */
  let callSearch: () => void;
  const handleCallSearch = () => {
    setSearchCallComplete(false);
    callSearch();
  };
  const attachCallSearch = (callback: () => void) => {
    callSearch = callback;
  };

  const [searchCallComplete, setSearchCallComplete] = useState<boolean>(false);
  const [locationsCallComplete, setLocationsCallComplete] =
    useState<boolean>(false);
  const [createCallComplete, setCreateCallComplete] = useState<boolean>(true);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(true);
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

  useEffect(() => {
    if (isLoggingOut) {
      setShowLoadingSpinner(true);
    } else {
      const unauthModalIsShowing =
        authData.auth_flow_complete && !authData.authed;
      if (unauthModalIsShowing) {
        setShowLoadingSpinner(false);
      } else {
        setShowLoadingSpinner(
          !locationsCallComplete || !searchCallComplete || !createCallComplete
        );
      }
    }
  }, [
    locationsCallComplete,
    searchCallComplete,
    createCallComplete,
    authData.auth_flow_complete,
    authData.authed,
    isLoggingOut,
  ]);

  /**
   * To allow a button in the ViewNotifications component
   * to trigger an action in the CreateNotificationForm
   * component, we create 3 functions:
   *
   * - updateHandle is a function that takes a function and
   * assigns it to callScroll. We pass updateHandle down
   * through CreateNotificationForm, where a sub-component
   * calls it with the function we want to attach.
   *
   * - callScroll will reference the function we want to call,
   * after the initial render.
   *
   * - handleScrollClick is passed down to ViewNotifications
   * where it can be assigned to the button we use as a trigger.
   *
   */
  const handleScrollClick = () => callScroll();
  let callScroll: () => void;
  const attachScrollClick = (callback: () => void) => {
    callScroll = callback;
  };

  const [locations, setLocations] = useState<Location[]>([]);
  const [locationStationLookup, setLocationStationLookup] =
    useState<LocationStationLookup>({});

  const getLocations = useCallback(async () => {
    try {
      const response = await backendApiService.get<Location[]>(`/location`);
      if (response.status === 200 && response.data) {
        const responseData = response.data;
        setLocations(responseData);
        const locationLookup: LocationStationLookup = {};
        responseData.forEach((loc) => (locationLookup[loc.station_code] = loc));
        setLocationStationLookup(locationLookup);
      }
    } catch (e) {
      console.error(e);
    }
    console.debug('[App] (getLocations) Locations Complete!');
    setLocationsCallComplete(true);
  }, []);

  useEffect(() => {
    if (authData.auth_flow_complete) {
      if (authData.authed) getLocations();
      else setLocationsCallComplete(true);
    }
  }, [authData, getLocations]);

  return (
    <SWAThemeProvider>
      <CookiesProvider>
        <DateTimeProvider dateAdapter={BaseAdapterDateFns}>
          <EnvironmentContext.Provider value={environmentData}>
            <AuthContext.Provider value={authContext}>
              <LoadingScreen showLoading={showLoadingSpinner} />
              {!authData.auth_flow_complete || isLoggingOut ? (
                <div />
              ) : authData.auth_flow_complete && !authData.authed ? (
                <UnauthorizedModal authorize={() => reauthorize()} />
              ) : (
                <CleanGrid>
                  <TopNav
                    shortSubtitle={''}
                    primaryActions={[
                      <LogoutButton logout={logout} isPrimary={true} />, // This is the logout button in the hamburger menu
                    ]}
                    secondaryActions={[
                      <LogoutButton logout={logout} isPrimary={false} />, // This is the logout button on the upper right when there is not a hamburger menu
                    ]}
                  />
                  <Header />
                  <CleanRow>
                    {!matchesMD ? (
                      <ViewNotifications
                        handleCreateScrollClick={handleScrollClick}
                        attachSearch={attachCallSearch}
                        setSearchCallComplete={setSearchCallComplete}
                        locationStationLookup={locationStationLookup}
                      />
                    ) : null}
                    <CreateNotificationForm
                      attachScroll={attachScrollClick}
                      callSearch={handleCallSearch}
                      locations={locations}
                      setCreateCallComplete={setCreateCallComplete}
                    />
                    {matchesMD ? (
                      <ViewNotifications
                        handleCreateScrollClick={handleScrollClick}
                        attachSearch={attachCallSearch}
                        setSearchCallComplete={setSearchCallComplete}
                        locationStationLookup={locationStationLookup}
                      />
                    ) : null}
                  </CleanRow>
                </CleanGrid>
              )}
            </AuthContext.Provider>
          </EnvironmentContext.Provider>
        </DateTimeProvider>
      </CookiesProvider>
    </SWAThemeProvider>
  );
};
