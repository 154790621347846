import { FC, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Modal } from '@swacl/core';
import {
  ViewNotificationData,
  ViewNotificationPreferenceData,
} from '../view-notifications';
import {
  CleanGrid,
  CleanRow,
  EditNotificationPreferences,
  ModalNotificationButtonsRow,
  NotificationFlightDetail,
} from '../shared';
import {
  AuthContext,
  IStyledProps,
  NotificationType,
  useMatchingMedia,
  EditNotificationFormPreferenceData,
  PutSubscriptionsRequest,
  EnvironmentContext,
} from '../../shared';
import {
  DeleteSubscriptionsResponse,
  PutSubscriptionsResponse,
} from '@crew/fsm-mobility-models-library';
import { backendApiService } from '../../shared/services/http-service';
import { formatInTimeZone } from 'date-fns-tz';

const UpdateHeaderBase: FC<IStyledProps> = ({ className = '' }) => {
  return (
    <CleanRow className={className}>
      <h2>Update Notification</h2>
    </CleanRow>
  );
};
const UpdateHeader = styled(UpdateHeaderBase)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0px;
`;

const UpdateNotificationFlightDetail = styled(NotificationFlightDetail)`
  text-align: left;
  margin-top: 32px;
`;

const UpdateNotificationBodyBase: FC<
  IStyledProps & {
    viewNotificationData: ViewNotificationData;
    closeFn: () => void;
    callSearch: () => void;
    setNotificationsPreferences: (
      notification: ViewNotificationData,
      newNotificationPreferences: ViewNotificationPreferenceData[]
    ) => void;
  }
> = ({
  className = '',
  viewNotificationData,
  closeFn,
  callSearch,
  setNotificationsPreferences,
}) => {
  const authData = useContext(AuthContext);
  const envData = useContext(EnvironmentContext);

  const [updateBtnDisabled, setUpdateBtnDisabled] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notificationPreferences, setNotificationPreferences] = useState<
    EditNotificationFormPreferenceData[]
  >(viewNotificationData.notificationPreferences);
  const [notificationSelectionTypes, setNotificationSelectionTypes] = useState<
    NotificationType[]
  >(
    viewNotificationData.notificationPreferences.map(
      (pref) => pref.notificationType
    )
  );

  /**
   *
   * @param input The notification preference
   * to add to the current preferences.
   */
  const addNotificationPreference = (
    input: EditNotificationFormPreferenceData
  ) => {
    console.debug('[UpdateNotificationForm] (addNotificationPreference) init');
    setNotificationPreferences((currentPreferences) => {
      console.debug(
        '[UpdateNotificationForm] currentPreferences',
        currentPreferences
      );
      const preferences = [...currentPreferences, input];
      console.debug('[UpdateNotificationForm] new Preferences', preferences);
      return preferences;
    });
  };

  /**
   *
   * @param notificationType The type of the notification
   * preference to remove from the current preferences.
   */
  const removeNotificationPreference = (notificationType: NotificationType) => {
    console.debug('[UpdateNotificationForm] Removing preference');
    setNotificationPreferences((currentPreferences) => {
      const filteredPreferences = currentPreferences.filter(
        (pref) => pref.notificationType !== notificationType
      );
      return filteredPreferences;
    });
  };

  const addNotificationSelectionType = (input: NotificationType) => {
    console.debug(
      '[UpdateNotificationForm] (addNotificationSelectionType) init'
    );
    setNotificationSelectionTypes((currentSelectionTypes) => {
      console.debug(
        '[UpdateNotificationForm] (addNotificationSelectionType) Adding selection type'
      );

      const selectionTypes = [...currentSelectionTypes, input];
      console.debug(selectionTypes);

      return selectionTypes;
    });
  };

  const removeNotificationSelectionType = (input: NotificationType) => {
    setNotificationSelectionTypes((currentSelectionTypes) => {
      console.debug(
        '[UpdateNotificationForm] (removeNotificationSelectionType) Removing selection type'
      );
      const filteredSelectionTypes = currentSelectionTypes.filter(
        (selection) => selection !== input
      );
      console.debug(filteredSelectionTypes);

      return filteredSelectionTypes;
    });
  };

  /**
   * @param initialPreferences The initial notification preferences
   * @param currentPreferences The current notification preferences
   * @param selectionTypes The types of preferences selected by check box
   * @description
   */
  const updateUpdateBtnDisabled = (
    initialPreferences: EditNotificationFormPreferenceData[],
    currentPreferences: EditNotificationFormPreferenceData[],
    selectionTypes: NotificationType[]
  ) => {
    console.debug(
      '[UpdateNotificationForm] (updateUpdateBtnDisabled) preferences',
      currentPreferences
    );
    console.debug(
      '[UpdateNotificationForm] (updateUpdateBtnDisabled) initial preferences',
      initialPreferences
    );
    console.debug(
      '[UpdateNotificationForm] (updateUpdateBtnDisabled) selections',
      selectionTypes
    );
    if (currentPreferences.length > 0) {
      // check fields are filled
      /**
       * map selection types to array of whether
       * the selection type is in the notification preferences,
       * where if it isn't, use true, and if it is, use false.
       * Then reduce using Or so that disable is true if any
       * of the values in the array are true, indicating that
       * at least one of the selectionTypes is not present
       * in the preferences.
       */
      const selectionsFilled = selectionTypes
        .map((selection) => {
          return currentPreferences.find(
            (pref) => pref.notificationType === selection
          ) === undefined
            ? false
            : true;
        })
        .reduce((prev, cur) => prev && cur, true);
      if (!selectionsFilled) {
        setUpdateBtnDisabled(true);
        return;
      }

      if (initialPreferences.length !== currentPreferences.length) {
        setUpdateBtnDisabled(false);
        return;
      }

      // compare current preferences against previous preferences
      const samePrefs: boolean = currentPreferences
        .map((pref) => {
          return initialPreferences.find((curPref) => {
            if (pref.notificationType !== curPref.notificationType)
              return false;
            if (pref.notificationType === NotificationType.SMS) {
              return (
                pref.notificationAddress.replace(/\D/g, '') ===
                curPref.notificationAddress.replace(/\D/g, '')
              );
            } else {
              return pref.notificationAddress === curPref.notificationAddress;
            }
          }) === undefined
            ? false
            : true;
        })
        .reduce((prev, cur) => prev && cur, true);

      console.log('SamePrefs', samePrefs);
      setUpdateBtnDisabled(samePrefs);
    } else {
      // There are no valid prefences
      setUpdateBtnDisabled(true);
    }
  };

  useEffect(() => {
    updateUpdateBtnDisabled(
      viewNotificationData.notificationPreferences,
      notificationPreferences,
      notificationSelectionTypes
    );
  }, [
    viewNotificationData.notificationPreferences,
    notificationPreferences,
    notificationSelectionTypes,
  ]);

  const saveSubscriptionApi = (
    notificationPreference: ViewNotificationPreferenceData
  ) => {
    let userId: string;
    if (authData.authed && authData.userId) {
      userId = authData.userId;
    } else if (envData.USE_AUTH === false) {
      userId = '';
    } else {
      console.error(
        '[UpdateNotification] (saveSubscriptionApi) User must be either authenticated or the environment context must have USE_AUTH = false'
      );
      return;
    }
    const departureTimeZone = viewNotificationData.departureTimeZone
      ? viewNotificationData.departureTimeZone
      : 'America/Chicago';
    const data: PutSubscriptionsRequest = {
      crew_member_id: userId,
      flight_number: viewNotificationData.flightNumber,
      departure_station: viewNotificationData.departureCode,
      departure_date: formatInTimeZone(
        viewNotificationData.departureDatetime,
        departureTimeZone,
        'yyyy-MM-dd'
      ), // departure_date must be in flight departure local time for subscriptions,
      channel_type: notificationPreference.notificationType,
      channel_address: notificationPreference.notificationAddress,
    };
    return backendApiService.put<PutSubscriptionsResponse>(
      `/subscriptions`,
      data
    );
  };

  const deleteSubscriptionApi = (
    notificationPreference: ViewNotificationPreferenceData
  ) => {
    const params = {
      crew_member_id: authData.userId,
      flight_number: viewNotificationData.flightNumber,
      departure_station: viewNotificationData.departureCode,
      departure_date: formatInTimeZone(
        viewNotificationData.departureDatetime,
        'America/Chicago',
        'yyyy-MM-dd'
      ), // departure_date must be in CT for subscriptions,
      channel_type: notificationPreference.notificationType,
      channel_address: notificationPreference.notificationAddress,
    };
    return backendApiService.delete<DeleteSubscriptionsResponse>(
      `/subscriptions`,
      {
        params: params,
      }
    );
  };

  const onUpdatePreference = async () => {
    const deletePreferences = viewNotificationData.notificationPreferences.map(
      (initPref) =>
        notificationPreferences.find(
          (curPref) => curPref.notificationType === initPref.notificationType
        ) === undefined
          ? initPref
          : null
    );
    console.log(
      '[UpdateNotificationBody] (onUpdatePreference) deletePreferences',
      deletePreferences
    );
    for (const notificationPreference of notificationPreferences) {
      await saveSubscriptionApi(notificationPreference);
    }
    for (const deletePreference of deletePreferences) {
      if (deletePreference !== null)
        await deleteSubscriptionApi(deletePreference);
    }
    const notifications: ViewNotificationPreferenceData[] =
      notificationPreferences.map((notificationPreference) => ({
        notificationType: notificationPreference.notificationType,
        notificationAddress: notificationPreference.notificationAddress,
      }));
    setNotificationsPreferences(viewNotificationData, notifications);
    closeFn();
    callSearch();
  };

  const CancelButton: FC = () => (
    <Button id={'cancel'} buttonType={'tertiary'} onClick={closeFn}>
      Cancel
    </Button>
  );

  const ActionButton: FC = () => (
    <Button
      id={'update'}
      buttonType={'primary'}
      disabled={updateBtnDisabled}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick={onUpdatePreference}
    >
      Update
    </Button>
  );

  return (
    <CleanGrid className={className}>
      {/* Header */}
      <UpdateHeader />
      {/* Flight Details */}
      <UpdateNotificationFlightDetail
        viewNotificationData={viewNotificationData}
        isModal={true}
        detailsLoaded={viewNotificationData.flightDetailSet}
      />
      {/* Notification Preferences */}
      <EditNotificationPreferences
        initialNotificationPrefences={
          viewNotificationData.notificationPreferences
        }
        addNotificationPreference={addNotificationPreference}
        removeNotificationPreference={removeNotificationPreference}
        addNotificationSelectionType={addNotificationSelectionType}
        removeNotificationSelectionType={removeNotificationSelectionType}
        isModal={true}
      />
      {/* Buttons */}
      <ModalNotificationButtonsRow
        CancelButton={CancelButton}
        ActionButton={ActionButton}
      />
    </CleanGrid>
  );
};

const UpdateNotificationBody = styled(UpdateNotificationBodyBase)`
  // prevents body content from overflowing the modal.
  // manually calculated
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  // the modal is automatically adding an additional 30px margin top
  // but probably shouldn't be.
  // Could adjust with position relative or negative margin if necessary.
`;

interface Props {
  propOpenModal: boolean;
  closeFn: () => void;
  callSearch: () => void;
  viewNotificationData: ViewNotificationData | null;
  setNotificationsPreferences: (
    notification: ViewNotificationData,
    newNotificationPreferences: ViewNotificationPreferenceData[]
  ) => void;
}

export const UpdateNotification: FC<Props> = ({
  propOpenModal,
  closeFn,
  callSearch,
  viewNotificationData,
  setNotificationsPreferences,
}) => {
  const modalWidth = useMatchingMedia('lg') ? 471 : 368;
  const bodyContent =
    viewNotificationData !== null ? (
      <UpdateNotificationBody
        viewNotificationData={viewNotificationData}
        closeFn={closeFn}
        callSearch={callSearch}
        setNotificationsPreferences={setNotificationsPreferences}
      />
    ) : null;

  return (
    <Modal
      open={propOpenModal}
      closeFn={closeFn}
      bodyContent={bodyContent}
      width={modalWidth}
      blockClose={true} // prevents closing by clicking outside of modal
      showClose={false}
    />
  );
};
