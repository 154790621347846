import { ReactNode } from 'react';
import styled from '@emotion/styled';

import { CleanRow } from '../shared/CleanGrid';

export const NotificationFlightTextA = styled(
  ({
    children,
    className = '',
  }: {
    children: ReactNode;
    className?: string;
  }) => {
    return <span className={className}>{children}</span>;
  }
)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  @media (prefers-color-scheme: light) {
    color: var(--colors-gray-8);
  }
  @media (prefers-color-scheme: dark) {
    color: var(--colors-gray-2);
  }
`;

export const NotificationFlightTextB = styled(
  ({
    children,
    className = '',
  }: {
    children: ReactNode;
    className?: string;
  }) => {
    return <span className={className}>{children}</span>;
  }
)`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  @media (prefers-color-scheme: light) {
    color: var(--colors-gray-6);
  }
  @media (prefers-color-scheme: dark) {
    color: var(--colors-gray-4);
  }
`;

export const NotificationFlightTextC = styled(
  ({
    children,
    className = '',
  }: {
    children: ReactNode;
    className?: string;
  }) => {
    return <span className={className}>{children}</span>;
  }
)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  @media (prefers-color-scheme: light) {
    color: var(--colors-gray-10);
  }
  @media (prefers-color-scheme: dark) {
    color: var(--colors-gray-1);
  }
`;

export const NotificationFlightCode = styled(
  ({
    children,
    className = '',
  }: {
    children: ReactNode;
    className?: string;
  }) => {
    return <span className={className}>{children}</span>;
  }
)`
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  @media (prefers-color-scheme: light) {
    color: var(--colors-gray-10);
  }
  @media (prefers-color-scheme: dark) {
    color: var(--colors-gray-1);
  }
`;

export const LocationsWrapper = styled.div`
  margin-top: 4px;
`;

export const FlightCodesRow = styled(CleanRow)`
  margin-top: 16px;
`;
