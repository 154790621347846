import { useState, useEffect } from 'react';

/**
 * Thematic breakpoints defined by the SWA Component Library
 */
export const breakpoints: { [index: string]: number } = {
  md: 767,
  lg: 1024,
  xl: 1248,
};

/**
 * Helper map for mapping a `breakpoint` to the
 * corresponding px value.
 */
export const breakpointQueryMap = Object.keys(breakpoints)
  .map((key) => [key, breakpoints[key]] as [string, number])
  .reduce((prev, [key, breakpoint]) => {
    prev[key] = `${breakpoint}px`;
    return prev;
  }, {} as { [index: string]: string });

/**
 * Helper map for mapping a `breakpoint` to the
 * corresponding min-width media query.
 */
export const mediaQueryMap = Object.keys(breakpoints)
  .map((key) => [key, breakpoints[key]] as [string, number])
  .reduce((prev, [key, breakpoint]) => {
    prev[key] = `@media (min-width: ${breakpoint}px)`;
    return prev;
  }, {} as { [index: string]: string });

/**
 *
 * @param breakpoint The breakpoint designation string specified in `breakpoints`.
 * @returns Whether the current window matches the media query defined
 * by `(min-width: ${breakpointQueryMap[breakpoint]})` e.g. input 'md'
 * will return true if the window matches `(min-width: 767px)`.
 */
export const useMatchingMedia = (breakpoint: string): boolean => {
  const [isMatching, setIsMatching] = useState<boolean>(false);

  const mediaQuery = `(min-width: ${breakpointQueryMap[breakpoint]})`;

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsMatching(window.matchMedia(mediaQuery).matches);
      };

      // Add event listener
      window.addEventListener('resize', handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [mediaQuery]);

  return isMatching;
};
