import styled from '@emotion/styled';
import { FC, PropsWithChildren, useCallback, useEffect, useRef } from 'react';

import { IStyledProps, mediaQueryMap } from '../../shared';

interface Props {
  attachScroll: (callback: () => void) => void;
}

export const NotificationPrimaryWrapperBase: FC<
  PropsWithChildren<IStyledProps & Props>
> = ({ children, className = '', attachScroll }) => {
  /**
   * We use useRef here to attach a reference to the
   * div element so that we can call it's `scrollIntoView()` method.
   */
  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperScroll = useCallback(() => {
    wrapperRef.current?.scrollIntoView();
  }, []);

  /**
   * We call the passed in method with the internally
   * defined `wrapperScroll`. The method passed in should
   * assign `wrapperScroll` to a parent variable for use
   * in the parent component.
   */
  useEffect(() => {
    if (attachScroll) attachScroll(wrapperScroll);
  }, [attachScroll, wrapperScroll]);

  /**
   * I would like to replace the below with the WrapperBase,
   * but that would require using ref on the WrapperBase,
   * and I'm not sure if I want to avoid that or not.
   */
  return (
    <div
      ref={wrapperRef}
      className={`grid__column-12--sm grid__column-6--md grid__column-6 ${className}`}
    >
      {children}
    </div>
  );
};

export const NotificationFormWrapper = styled(NotificationPrimaryWrapperBase)`
  margin: 0px;
  margin-top: 16px;
  ${mediaQueryMap['md']} {
    margin: 16px;
  }
  ${mediaQueryMap['lg']} {
    margin: 24px;
  }

  padding: 8px 16px;
  ${mediaQueryMap['lg']} {
    padding: 24px;
  }

  background-color: var(--global-panel-color);
`;
