import {
  AuthorizationListener,
  getCodeVerifierFromAuthorizationRequest,
  OAuth,
  StringMap,
  TokenResponse,
} from '@ef/oauth-js-client';
import { EnvironmentData } from '.';

export const oauthListenerFactory = (params: {
  oauth: OAuth;
  errorHandler: () => void;
  codeExchangeExternalParams: {
    redirectUri: string;
    audience?: string;
    extras?: StringMap;
  };
  successfulExchangeHandler: (tokenResponse: TokenResponse) => void;
}): AuthorizationListener => {
  const oauthListener: AuthorizationListener = async (
    request,
    response,
    error
  ) => {
    console.debug(
      '[App] (OAuth) Authorization request complete: ',
      request,
      response,
      error
    );
    if (error || !response) {
      params.errorHandler();
      return;
    }

    console.debug(`[App] (OAuth) Authorization Code:  ${response.code}`);
    try {
      console.debug(
        '[App] (OAuth) Fetching Authorization Service Configuration'
      );

      // required call by library
      await params.oauth.fetchAuthorizationServiceConfiguration();

      console.debug(
        '[App] (OAuth) COMPLETE Fetching Authorization Service Configuration'
      );

      // required call by library
      const codeVerifier = getCodeVerifierFromAuthorizationRequest(request);

      console.debug('[App] (OAuth) Exchanging Authorization Code');

      // call ping to obtain authorization token
      const tokenResponse = await params.oauth.authorizationCodeExchange(
        response.code,
        params.codeExchangeExternalParams.redirectUri,
        codeVerifier,
        params.codeExchangeExternalParams.audience
      );
      console.debug('[App] (OAuth) COMPLETE Exchanging Authorization Code');
      console.debug(
        `[App] (OAuth) Authorization Token:  ${tokenResponse.accessToken}`
      );
      params.successfulExchangeHandler(tokenResponse);
    } catch (err) {
      console.error(`[App] (OAuth) FAILED to acquire access token!}`);
      console.error(err);
      params.errorHandler();
      return;
    }
  };
  return oauthListener;
};

export const validatePingEnvironment = (envData: EnvironmentData): boolean => {
  if (
    envData.PING_CLIENT_ID == null ||
    envData.PING_CLIENT_SECRET == null ||
    envData.PING_OPEN_ID_CONNECT_URL == null ||
    envData.PING_REDIRECT_URI == null ||
    envData.PING_SCOPE == null ||
    envData.PING_ALLOWED_GROUPS == null
  ) {
    console.debug(
      '[App] (OAuth) Unable to long in due to missing environment data',
      {
        PING_CLIENT_ID: envData.PING_CLIENT_ID ?? 'Missing',
        PING_CLIENT_SECRET: envData.PING_CLIENT_SECRET ?? 'Missing',
        PING_OPEN_ID_CONNECT_URL: envData.PING_OPEN_ID_CONNECT_URL ?? 'Missing',
        PING_REDIRECT_URI: envData.PING_REDIRECT_URI ?? 'Missing',
        PING_SCOPE: envData.PING_SCOPE ?? 'Missing',
        PING_ALLOWED_GROUPS: envData.PING_ALLOWED_GROUPS ?? 'Missing',
      }
    );
    return false;
  }
  return true;
};
