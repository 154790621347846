import { GetFlightDetailGetResponse } from '@crew/fsm-mobility-models-library';

export const mock_flight_details_dictionary: Record<
  string,
  GetFlightDetailGetResponse
> = {
  '20241113-3942-BLI': {
    flight_leg_key: '20241113-3942-BLI',
    departure_date: '2024-11-13',
    original_departure_time: '2024-11-13T10:15:00Z',
    current_departure_time: '2024-11-13T10:15:00Z',
    original_arrival_time: '2024-11-13T13:30:00Z',
    current_arrival_time: '2024-11-13T13:30:00Z',
    outbound_status: 'On Time',
    inbound_status: 'On Time',
    departure_station_code: 'BLI',
    arrival_station_code: 'HOU',
    departure_station_time_zone: 'America/Los_Angeles',
    arrival_station_time_zone: 'America/Chicago',
    departure_gate: 'F22',
    arrival_gate: 'B45',
    created_by: 'Me',
    created_time: '2024-13-09T15:16:17Z',
    last_modified_by: 'Me',
    last_modified_time: '2024-13-09T15:16:17Z',
    scheduled_notification_sent: false,
    expiration_date: 1,
  },
  '20241209-1234-DAL': {
    flight_leg_key: '20241209-1234-DAL',
    departure_date: '2024-11-13',
    original_departure_time: '2024-11-13T10:15:00Z',
    current_departure_time: '2024-11-13T10:15:00Z',
    original_arrival_time: '2024-11-13T13:30:00Z',
    current_arrival_time: '2024-11-13T13:30:00Z',
    outbound_status: 'On Time',
    inbound_status: 'On Time',
    departure_station_code: 'DAL',
    arrival_station_code: 'HOU',
    departure_station_time_zone: 'America/Chicago',
    arrival_station_time_zone: 'America/Chicago',
    departure_gate: 'F22',
    arrival_gate: 'B45',
    created_by: 'Me',
    created_time: '2024-13-09T15:16:17Z',
    last_modified_by: 'Me',
    last_modified_time: '2024-13-09T15:16:17Z',
    scheduled_notification_sent: false,
    expiration_date: 1,
  },
  '20241230-987-HOU': {
    flight_leg_key: '20241230-987-HOU',
    departure_date: '2024-12-03',
    original_departure_time: '2024-12-31T03:15:00Z',
    current_departure_time: '2024-12-31T03:15:00Z',
    original_arrival_time: '2024-12-31T08:30:00Z',
    current_arrival_time: '2024-12-31T08:30:00Z',
    outbound_status: 'On Time',
    inbound_status: 'On Time',
    departure_station_code: 'HOU',
    arrival_station_code: 'BLI',
    departure_station_time_zone: 'America/Chicago',
    arrival_station_time_zone: 'America/Los_Angeles',
    departure_gate: 'F22',
    arrival_gate: 'B45',
    created_by: 'Me',
    created_time: '2024-13-09T15:16:17Z',
    last_modified_by: 'Me',
    last_modified_time: '2024-13-09T15:16:17Z',
    scheduled_notification_sent: false,
    expiration_date: 1,
  },
  '20241109-5555-DEN': {
    flight_leg_key: '20241109-5555-DEN',
    departure_date: '2024-11-13',
    original_departure_time: '2024-11-13T10:15:00Z',
    current_departure_time: '2024-11-13T10:15:00Z',
    original_arrival_time: '2024-11-13T13:30:00Z',
    current_arrival_time: '2024-11-13T13:30:00Z',
    outbound_status: 'On Time',
    inbound_status: 'On Time',
    departure_station_code: 'DEN',
    arrival_station_code: 'HOU',
    departure_station_time_zone: 'America/Denver',
    arrival_station_time_zone: 'America/Chicago',
    departure_gate: 'F22',
    arrival_gate: 'B45',
    created_by: 'Me',
    created_time: '2024-13-09T15:16:17Z',
    last_modified_by: 'Me',
    last_modified_time: '2024-13-09T15:16:17Z',
    scheduled_notification_sent: false,
    expiration_date: 1,
  },
};
