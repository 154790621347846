import { NonFunctionProperties } from '@crew/fsm-mobility-models-library';
import { createContext } from 'react';

export class EnvironmentData {
  constructor(
    public ENVIRONMENT_NAME?: string,
    public NODE_ENV?: string,
    public API_URL?: string,
    public MOCK_API?: boolean,
    public FAKE_DATA?: boolean,
    public USE_AUTH?: boolean,
    public READ_AUTH_TOKEN?: boolean,
    public PING_CLIENT_ID?: string,
    public PING_CLIENT_SECRET?: string,
    public PING_OPEN_ID_CONNECT_URL?: string,
    public PING_REDIRECT_URI?: string,
    public PING_SCOPE?: string,
    public PING_AUDIENCE?: string,
    public PING_ALLOWED_GROUPS?: string[],
    public FAKE_USER_ID?: string,
    public FAKE_USER_EMAIL?: string,
    public ALLOW_USER_FLIGHTS?: boolean,
    public ALLOW_USER_EMAIL?: boolean,
    public PING_LOGOUT_URL?: string
  ) {}

  static newEnvironmentData(
    obj: NonFunctionProperties<EnvironmentData>
  ): EnvironmentData {
    return new EnvironmentData(
      obj.ENVIRONMENT_NAME,
      obj.NODE_ENV,
      obj.API_URL,
      obj.MOCK_API,
      obj.FAKE_DATA,
      obj.USE_AUTH,
      obj.READ_AUTH_TOKEN,
      obj.PING_CLIENT_ID,
      obj.PING_CLIENT_SECRET,
      obj.PING_OPEN_ID_CONNECT_URL,
      obj.PING_REDIRECT_URI,
      obj.PING_SCOPE,
      obj.PING_AUDIENCE,
      obj.PING_ALLOWED_GROUPS,
      obj.FAKE_USER_ID,
      obj.FAKE_USER_EMAIL,
      obj.ALLOW_USER_FLIGHTS,
      obj.ALLOW_USER_EMAIL,
      obj.PING_LOGOUT_URL
    );
  }
}

export const EnvironmentContext = createContext<EnvironmentData>({});
