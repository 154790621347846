import { FC, PropsWithChildren } from 'react';

import { IStyledProps } from '../../shared';

/**
 *
 * @param Props The children for this component (supplied between the tags),
 * the className for styling,
 * and the columns array for the SWA grid.
 * @returns A functional component wrapper that can be styled using `@emotion/syled`.
 */
export const WrapperBase: FC<
  PropsWithChildren<IStyledProps & { columns?: number[] }>
> = ({ children, className = '', columns }) => {
  const gridClassname =
    columns === undefined
      ? className
      : `grid__column-${columns[0]}--sm grid__column-${columns[1]}--md grid__column-${columns[2]} ${className}`;

  return <div className={gridClassname}>{children}</div>;
};
