import { FC, useContext, useEffect, useState } from 'react';
import { DatePicker } from '@swacl/core';
import { addDays, subDays, formatISO } from 'date-fns';
import { NotificationFormWrapper } from './CreateNotificationForm.styled';
import { CreateNotificationButton } from './CreateNotificationButton';
import { AirportCityPair } from './AirportCityPair';
import { FlightNumberSelect } from './FlightNumberSelect';
import {
  HR,
  FormRow,
  EditNotificationPreferences,
  StyledDatePickerWrapper,
} from '../shared';
import {
  AuthContext,
  EditNotificationFormPreferenceData,
  EnvironmentContext,
  NotificationType,
} from '../../shared';
import {
  PutSubscriptionsRequest,
  PutSubscriptionsResponse,
  Location,
} from '@crew/fsm-mobility-models-library';
import { backendApiService } from '../../shared';

interface Props {
  attachScroll: (callback: () => void) => void;
  callSearch: () => void;
  locations: Location[];
  setCreateCallComplete: (flag: boolean) => void;
}

export const CreateNotificationForm: FC<Props> = ({
  attachScroll,
  callSearch,
  locations,
  setCreateCallComplete,
}) => {
  // notification preference data
  const [departureCity, setDepartureCity] = useState<string | null>(null);
  const [arrivalCity, setArrivalCity] = useState<string | null>(null);
  const [departureDate, setDepartureDate] = useState<Date | null>(null);
  const [flightNumber, setFlightNumber] = useState<string | null>(null);
  const [notificationPreferences, setNotificationPreferences] = useState<
    EditNotificationFormPreferenceData[]
  >([]);
  const [notificationSelectionTypes, setNotificationSelectionTypes] = useState<
    NotificationType[]
  >([]);

  // form fields disabled
  const [fltNumInputDisabled, setFltNumInputDisabled] = useState<boolean>(true);
  const [createBtnDisabled, setCreateBtnDisabled] = useState<boolean>(true);

  const authData = useContext(AuthContext);
  const environmentData = useContext(EnvironmentContext);

  // update handlers for children
  const updateDepartureCity = (input: string | null) => {
    setDepartureCity(input);
  };
  const updateArrivalCity = (input: string | null) => {
    setArrivalCity(input);
  };
  const onDateChange = (newDate: Date) => {
    setDepartureDate(newDate);
  };
  const updateFlightNumber = (input: string | null) => {
    setFlightNumber(input);
  };

  const addNotificationPreference = (
    input: EditNotificationFormPreferenceData
  ) => {
    console.debug('[CreateNotificationForm] (addNotificationPreference) init');
    setNotificationPreferences((currentPreferences) => {
      console.debug('[CreateNotificationForm] Adding preference');

      const preferences = [...currentPreferences, input];
      console.log(preferences);
      return preferences;
    });
  };

  const removeNotificationPreference = (notifcationType: NotificationType) => {
    setNotificationPreferences((currentPreferences) => {
      console.debug('[CreateNotificationForm] Removing preference');
      const filteredPreferences = currentPreferences.filter(
        (pref) => pref.notificationType !== notifcationType
      );

      return filteredPreferences;
    });
  };

  const addNotificationSelectionType = (input: NotificationType) => {
    console.debug(
      '[CreateNotificationForm] (addNotificationSelectionType) init'
    );
    setNotificationSelectionTypes((currentSelectionTypes) => {
      console.debug(
        '[CreateNotificationForm] (addNotificationSelectionType) Adding selection type'
      );

      const selectionTypes = [...currentSelectionTypes, input];
      console.debug(selectionTypes);

      return selectionTypes;
    });
  };

  const removeNotificationSelectionType = (input: NotificationType) => {
    setNotificationSelectionTypes((currentSelectionTypes) => {
      console.debug(
        '[CreateNotificationForm] (removeNotificationSelectionType) Removing selection type'
      );
      const filteredSelectionTypes = currentSelectionTypes.filter(
        (selection) => selection !== input
      );
      console.debug(filteredSelectionTypes);

      return filteredSelectionTypes;
    });
  };

  // Functions for resetting form after submission
  let resetAirportPair: () => void;
  const attachResetAirportPair = (callback: () => void) => {
    resetAirportPair = callback;
  };

  let resetFlightNumber: () => void = () => undefined;
  const attachResetFlightNumber = (callback: () => void) => {
    resetFlightNumber = callback;
  };

  let removePreferences: () => void;
  const attachRemovePreferences = (callback: () => void) => {
    removePreferences = callback;
  };

  const resetFormData = () => {
    resetAirportPair();
    setDepartureDate(null);
    resetFlightNumber();
    removePreferences();
    setFltNumInputDisabled(true);
    setCreateBtnDisabled(true);
  };

  // Departure Date configuration
  const today = new Date();
  const minDate = subDays(today, 1);
  const maxDate = addDays(today, 14);

  // enable use of Flight Number input
  const updateBtnDisabled = (
    departureCity: string | null,
    arrivalCity: string | null,
    departureDate: Date | null,
    flightNumber: string | null,
    notificationPreferences: EditNotificationFormPreferenceData[],
    selectionTypes: NotificationType[]
  ) => {
    console.debug('[CreateNotificationForm] (updateBtnDisabled) input');
    console.debug({
      departureCity: departureCity,
      arrivalCity: arrivalCity,
      departureDate: departureDate,
      flightNumber: flightNumber,
      notificationPreferences: notificationPreferences,
      notificationSelectionTypes: selectionTypes,
    });

    if (
      departureCity !== null &&
      arrivalCity !== null &&
      departureDate !== null
    ) {
      setFltNumInputDisabled(false);
      if (
        notificationPreferences.length > 0 &&
        selectionTypes.length > 0 &&
        flightNumber !== null &&
        flightNumber !== ''
      ) {
        /**
         * map selection types to array of whether
         * the selection type is in the notification preferences,
         * where if it isn't, use true, and if it is, use false.
         * Then reduce using Or so that disable is true if any
         * of the values in the array are true, indicating that
         * at least one of the selectionTypes is not present
         * in the preferences.
         */
        const disable = selectionTypes
          .map((selection) => {
            return notificationPreferences.find(
              (pref) => pref.notificationType === selection
            ) === undefined
              ? true
              : false;
          })
          .reduce((prev, cur) => prev || cur, false);

        setCreateBtnDisabled(disable);
      } else {
        setCreateBtnDisabled(true);
      }
    } else {
      setFltNumInputDisabled(true);
      setCreateBtnDisabled(true);
    }
  };

  useEffect(() => {
    updateBtnDisabled(
      departureCity,
      arrivalCity,
      departureDate,
      flightNumber,
      notificationPreferences,
      notificationSelectionTypes
    );
  }, [
    departureCity,
    arrivalCity,
    departureDate,
    flightNumber,
    notificationPreferences,
    notificationSelectionTypes,
  ]);
  useEffect(() => {
    setFlightNumber(null);
  }, [departureDate, departureCity, arrivalCity]);

  const onClickHandler = async () => {
    if (environmentData.FAKE_DATA || environmentData.API_URL == null) {
      resetFormData();
      return;
    }

    console.debug(
      '[CreateNotificationForm] Sending Notifications',
      notificationPreferences
    );
    setCreateCallComplete(false);
    try {
      const responses = await Promise.all(
        notificationPreferences.map((item) => {
          const data = new PutSubscriptionsRequest(
            flightNumber === null ? undefined : flightNumber,
            departureDate === null
              ? undefined
              : formatISO(departureDate, { representation: 'date' }),
            departureCity === null ? undefined : departureCity,
            authData.userId,
            item.notificationType,
            item.notificationAddress
          );

          console.log(data);

          return backendApiService.put<PutSubscriptionsResponse>(
            `/subscriptions`,
            data
          );
        })
      );
      responses.forEach((response) =>
        console.debug(
          '[CreateNotificationForm] Sent Notification Response',
          response.data
        )
      );
      callSearch();
      resetFormData();
    } catch (error) {
      console.log(error);
    }
    setCreateCallComplete(true);
  };

  return (
    <NotificationFormWrapper attachScroll={attachScroll}>
      <h3>Create New Notification</h3>
      <HR />
      <AirportCityPair
        updateDepartureCity={updateDepartureCity}
        updateArrivalCity={updateArrivalCity}
        attachReset={attachResetAirportPair}
        locations={locations}
      />
      <FormRow>
        {/* This should be refactored out,
        but the DatePicker won't work when updating
        the parent component */}
        <StyledDatePickerWrapper>
          <DatePicker
            id={'departure-date'}
            date={departureDate}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            displayDateFormat='yyyy-MM-dd'
            label={'Departure Date'}
            minDate={minDate}
            maxDate={maxDate}
            onDateChange={onDateChange}
          />
        </StyledDatePickerWrapper>
        <FlightNumberSelect
          disabled={fltNumInputDisabled}
          updateFlightNumber={updateFlightNumber}
          attachReset={attachResetFlightNumber}
          date={departureDate}
          departureCity={departureCity}
          arrivalCity={arrivalCity}
        />
      </FormRow>
      <EditNotificationPreferences
        addNotificationPreference={addNotificationPreference}
        removeNotificationPreference={removeNotificationPreference}
        attachRemovePreferences={attachRemovePreferences}
        addNotificationSelectionType={addNotificationSelectionType}
        removeNotificationSelectionType={removeNotificationSelectionType}
      />
      <CreateNotificationButton
        disabled={createBtnDisabled}
        onClick={onClickHandler}
      />
    </NotificationFormWrapper>
  );
};
