import { FC } from 'react';
import { ProgressSpinner } from '@swacl/core';
import { IStyledProps } from '../../shared';
import { CleanRow } from '.';
import { ViewNotificationData } from '..';
import {
  NotificationFlightTextA,
  NotificationFlightTextB,
  NotificationFlightTextC,
  NotificationFlightCode,
  LocationsWrapper,
  FlightCodesRow,
} from './NotificationFlightDetails.style';

interface Props {
  viewNotificationData: ViewNotificationData;
  isModal?: boolean;
  detailsLoaded?: boolean;
}

export const NotificationFlightDetail: FC<IStyledProps & Props> = ({
  className = '',
  viewNotificationData,
  isModal = false,
  detailsLoaded = false,
}) => {
  const gridColumns = !isModal ? ['6', '6', '4'] : ['6', '6', '6'];

  const columnGridClass = `grid__column-${gridColumns[0]}--sm grid__column-${gridColumns[1]}--md grid__column-${gridColumns[2]}`;

  return (
    <div className={className}>
      <CleanRow>
        <div className={columnGridClass}>
          <label>Flight</label>
          <br />
          <NotificationFlightTextA>
            {viewNotificationData.flightNumber}
          </NotificationFlightTextA>
        </div>
        <div className={columnGridClass}>
          <label>Date</label>
          <br />
          <NotificationFlightTextA>
            {viewNotificationData.departureDateLocal}
          </NotificationFlightTextA>
        </div>
      </CleanRow>
      {detailsLoaded ? (
        <div>
          <CleanRow>
            <div
              className={
                'grid__column-12--sm grid__column-12--md grid__column-12'
              }
            >
              <LocationsWrapper>
                <NotificationFlightTextB>
                  From: {viewNotificationData.departureCity}
                </NotificationFlightTextB>
                <br />
                <NotificationFlightTextB>
                  To: {viewNotificationData.arrivalCity}
                </NotificationFlightTextB>
              </LocationsWrapper>
            </div>
          </CleanRow>
          <FlightCodesRow>
            <div className={columnGridClass}>
              <label>DEPARTS</label>
              <br />
              <NotificationFlightCode>
                {viewNotificationData.departureCode}
              </NotificationFlightCode>
              <br />
              <NotificationFlightTextC>
                {viewNotificationData.departureTimeLocal}
              </NotificationFlightTextC>
              <br />
              <NotificationFlightTextC>
                Gate {viewNotificationData.departureGate}
              </NotificationFlightTextC>
            </div>
            <div className={columnGridClass}>
              <label>ARRIVES</label>
              <br />
              <NotificationFlightCode>
                {viewNotificationData.arrivalCode}
              </NotificationFlightCode>
              <br />
              <NotificationFlightTextC>
                {viewNotificationData.arrivalTimeLocal}
              </NotificationFlightTextC>
              <br />
              <NotificationFlightTextC>
                Gate {viewNotificationData.arrivalGate}
              </NotificationFlightTextC>
            </div>
          </FlightCodesRow>
        </div>
      ) : (
        <div>
          <ProgressSpinner
            mode={'indeterminate'}
            showValue={false}
            id={'indeterminate-spinner'}
          />
        </div>
      )}
    </div>
  );
};
