import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ViewNotificationData } from '.';
import { IStyledProps, LocationStationLookup } from '../../shared';
import { ViewNotificationPreferences } from './ViewNotificationPreferences';
import { NotificationFlightDetail } from '../shared/NotificationFlightDetail';
import { HR } from '..';
import { Button } from '@swacl/core';

interface Props {
  viewNotificationData: ViewNotificationData;
  updateModalNotificationData: (input: ViewNotificationData) => void;
  deleteModalNotificationData: (notification: ViewNotificationData) => void;
  locationStationLookup: LocationStationLookup;
}

const ViewNotificationFlightDetail = styled(NotificationFlightDetail)`
  margin-top: 10px;
`;

const ViewNotificationBase: FC<Props & IStyledProps> = ({
  className = '',
  viewNotificationData,
  updateModalNotificationData,
  deleteModalNotificationData,
  locationStationLookup,
}) => {
  const [notification, setNotification] = useState<ViewNotificationData>();
  useEffect(() => {
    const depLoc = viewNotificationData.departureCode
      ? locationStationLookup[viewNotificationData.departureCode]
      : undefined;
    const depCity = depLoc
      ? `${depLoc.city}, ${
          depLoc.state_code ? depLoc.state_code : depLoc.country_name
        }`
      : undefined;

    const arrLoc = viewNotificationData.arrivalCode
      ? locationStationLookup[viewNotificationData.arrivalCode]
      : undefined;
    const arrCity = arrLoc
      ? `${arrLoc.city}, ${
          arrLoc.state_code ? arrLoc.state_code : arrLoc.country_name
        }`
      : undefined;

    setNotification(
      ViewNotificationData.NewViewNotificationDataObj({
        flightLegKey: viewNotificationData.flightLegKey,
        flightNumber: viewNotificationData.flightNumber,
        departureCity: depCity,
        departureDatetime: viewNotificationData.departureDatetime,
        notificationPreferences: viewNotificationData.notificationPreferences,
        departureDateLocal: viewNotificationData.departureDateLocal,
        departureCode: viewNotificationData.departureCode,
        departureGate: viewNotificationData.departureGate,
        departureTimeLocal: viewNotificationData.departureTimeLocal,
        arrivalDatetime: viewNotificationData.arrivalDatetime,
        arrivalCity: arrCity,
        arrivalCode: viewNotificationData.arrivalCode,
        arrivalGate: viewNotificationData.arrivalGate,
        arrivalTimeLocal: viewNotificationData.arrivalTimeLocal,
        flightDetailSet: viewNotificationData.flightDetailSet,
      })
    );
  }, [viewNotificationData, locationStationLookup]);
  const UpdateButton: FC = () => (
    <Button
      id={`Update-${(notification ?? viewNotificationData).flightLegKey}`}
      buttonType={'secondary'}
      onClick={() =>
        updateModalNotificationData(notification ?? viewNotificationData)
      }
    >
      Update
    </Button>
  );

  const DeleteButton: FC = () => (
    <Button
      id={`Delete-${(notification ?? viewNotificationData).flightLegKey}`} // unique id!
      buttonType={'tertiary'}
      onClick={() =>
        deleteModalNotificationData(notification ?? viewNotificationData)
      }
    >
      Delete
    </Button>
  );

  return (
    <div className={className}>
      <HR />
      <ViewNotificationFlightDetail
        viewNotificationData={notification ?? viewNotificationData}
        detailsLoaded={notification?.flightDetailSet}
      />
      <ViewNotificationPreferences
        preferences={viewNotificationData.notificationPreferences}
        UpdateButton={UpdateButton}
        DeleteButton={DeleteButton}
      />
    </div>
  );
};

export const ViewNotification = styled(ViewNotificationBase)`
  margin-bottom: 16px;
`;
