import { NotificationType } from '../../shared';
import { NonFunctionProperties } from '@crew/fsm-mobility-models-library';

export class ViewNotificationsData {
  constructor(public viewNotificationsData: ViewNotificationData[]) {}
}

export class ViewNotificationData {
  constructor(
    public flightLegKey: string,
    public flightNumber: string,
    public departureDatetime: Date,
    public departureCode: string,
    public notificationPreferences: ViewNotificationPreferenceData[],
    public departureDateLocal?: string,
    public departureCity?: string,
    public departureGate?: string,
    public departureTimeZone?: string,
    public departureTimeLocal?: string,
    public arrivalDatetime?: Date,
    public arrivalCity?: string,
    public arrivalCode?: string,
    public arrivalGate?: string,
    public arrivalTimeZone?: string,
    public arrivalTimeLocal?: string,
    public flightDetailSet?: boolean
  ) {}

  static NewViewNotificationDataObj(
    obj: NonFunctionProperties<ViewNotificationData>
  ): ViewNotificationData {
    return new ViewNotificationData(
      obj.flightLegKey,
      obj.flightNumber,
      obj.departureDatetime,
      obj.departureCode,
      obj.notificationPreferences,
      obj.departureDateLocal,
      obj.departureCity,
      obj.departureGate,
      obj.departureTimeZone,
      obj.departureTimeLocal,
      obj.arrivalDatetime,
      obj.arrivalCity,
      obj.arrivalCode,
      obj.arrivalGate,
      obj.arrivalTimeZone,
      obj.arrivalTimeLocal,
      obj.flightDetailSet
    );
  }
}

export class ViewNotificationPreferenceData {
  constructor(
    public notificationType: NotificationType,
    public notificationAddress: string
  ) {}
}
