import styled from '@emotion/styled';
import { FC } from 'react';
import { WrapperBase, CleanRow } from '.';
import { mediaQueryMap } from '../../shared';

const ModalNotificationsButtonsWrapper = styled(WrapperBase)``;

export const ModalNotificationButtonsRow = styled(
  ({
    className = '',
    CancelButton,
    ActionButton,
  }: {
    className?: string;
    CancelButton?: FC;
    ActionButton: FC;
  }) => {
    return (
      <CleanRow
        className={`grid__column-12--sm grid__column-12--md grid__column-12 ${className}`}
      >
        {CancelButton ? (
          <ModalNotificationsButtonsWrapper>
            <CancelButton />
          </ModalNotificationsButtonsWrapper>
        ) : null}
        <ModalNotificationsButtonsWrapper>
          <ActionButton />
        </ModalNotificationsButtonsWrapper>
      </CleanRow>
    );
  }
)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 24px;
  ${mediaQueryMap['md']} {
    margin-top: 48px;
  }
  ${mediaQueryMap['lg']} {
    justify-content: space-around;
  }
`;
