import { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  Dropdown,
  NormalizedDropdown,
  NormalizedDropdownList,
} from '@swacl/core';

import { FormRow, StyledFormElementWrapper } from '../shared';
import { EnvironmentContext } from '../../shared';
import { Location } from '@crew/fsm-mobility-models-library';

interface Props {
  updateDepartureCity: (input: string | null) => void;
  updateArrivalCity: (input: string | null) => void;
  attachReset: (callback: () => void) => void;
  locations: Location[];
}

export const AirportCityPair: FC<Props> = ({
  updateDepartureCity,
  updateArrivalCity,
  attachReset,
  locations,
}) => {
  const environmentData = useContext(EnvironmentContext);

  const [pairDepartureCity, setPairDepartureCity] =
    useState<NormalizedDropdown | null>(null);
  const [pairArrivalCity, setPairArrivalCity] =
    useState<NormalizedDropdown | null>(null);
  const [departureCityDropdownOptions, setDepartureCityDropdownOptions] =
    useState<NormalizedDropdown[] | null>(null);
  const [arrivalCityDropdownOptions, setArrivalCityDropdownOptions] = useState<
    NormalizedDropdown[] | null
  >(null);
  const [originalDropdownOptions, setOriginalDropdownOptions] = useState<
    NormalizedDropdown[] | null
  >(null);

  useEffect(() => {
    const dropdownOptions = locations.map((loc) => ({
      value: loc.station_code,
      label: `${loc.station_code} - ${loc.city}, ${
        loc.state_code ? loc.state_code : loc.country_name
      } (${loc.station_name})`,
    }));
    dropdownOptions.sort((a, b) => (a.value > b.value ? 1 : -1));
    setDepartureCityDropdownOptions(dropdownOptions);
    setArrivalCityDropdownOptions(dropdownOptions);
    setOriginalDropdownOptions(dropdownOptions);
  }, [locations]);

  const departureCityId = 'departure-city';
  const arrivalCityId = 'arrival-city';

  const onCityChange = (
    option: NormalizedDropdownList | NormalizedDropdown,
    id: string
  ) => {
    console.log(option);
    if (Array.isArray(option)) return;

    if (id === departureCityId) {
      setPairDepartureCity(option);
      updateDepartureCity(option.value);
      if (originalDropdownOptions)
        setArrivalCityDropdownOptions(
          originalDropdownOptions.filter((opt) => opt.value !== option.value)
        );
    } else {
      setPairArrivalCity(option);
      updateArrivalCity(option.value);
      if (originalDropdownOptions)
        setDepartureCityDropdownOptions(
          originalDropdownOptions.filter((opt) => opt.value !== option.value)
        );
    }
  };

  const resetCities = useCallback(() => {
    setPairDepartureCity(null);
    updateDepartureCity(null);
    setPairArrivalCity(null);
    updateArrivalCity(null);
    if (originalDropdownOptions) {
      setDepartureCityDropdownOptions(originalDropdownOptions);
      setArrivalCityDropdownOptions(originalDropdownOptions);
    }
  }, [updateDepartureCity, updateArrivalCity, originalDropdownOptions]);

  useEffect(() => {
    if (attachReset) attachReset(resetCities);
  }, [attachReset, resetCities]);

  const handleDropdownKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (
      event.key === 'Backspace' &&
      (event.target as HTMLInputElement).value === ''
    ) {
      if ((event.target as HTMLInputElement).id === departureCityId) {
        setPairDepartureCity(null);
        updateDepartureCity(null);
        setArrivalCityDropdownOptions(originalDropdownOptions);
      } else {
        setPairArrivalCity(null);
        updateArrivalCity(null);
        setDepartureCityDropdownOptions(originalDropdownOptions);
      }
    }
  };

  return (
    <FormRow>
      <StyledFormElementWrapper>
        <div onKeyDown={handleDropdownKeyDown}>
          <Dropdown
            id={departureCityId}
            label={'Departure City or Airport Code'}
            onChange={onCityChange}
            options={departureCityDropdownOptions ?? []}
            allowSearch={true}
            isSearchAndCreateType={environmentData.ALLOW_USER_FLIGHTS}
            value={pairDepartureCity}
          />
        </div>
      </StyledFormElementWrapper>

      <StyledFormElementWrapper>
        <div onKeyDown={handleDropdownKeyDown}>
          <Dropdown
            id={arrivalCityId}
            label={'Arrival City or Airport Code'}
            onChange={onCityChange}
            options={arrivalCityDropdownOptions ?? []}
            allowSearch={true}
            isSearchAndCreateType={environmentData.ALLOW_USER_FLIGHTS}
            value={pairArrivalCity}
          />
        </div>
      </StyledFormElementWrapper>
    </FormRow>
  );
};
