import { FC, useContext } from 'react';
import styled from '@emotion/styled';
import { Button, Modal } from '@swacl/core';
import { DeleteSubscriptionsResponse } from '@crew/fsm-mobility-models-library';
import { AuthContext, useMatchingMedia } from '../../shared';
import { CleanGrid, CleanRow, ModalNotificationButtonsRow } from '..';

import { ViewNotificationData } from '../../components';
import { backendApiService } from '../../shared/services/http-service';
import { formatInTimeZone } from 'date-fns-tz';

interface Props {
  openModal: boolean;
  closeFn: () => void;
  notification: ViewNotificationData | null;
  callSearch: () => void;
}

const DeleteNotificationHeader = styled(
  ({ className = '' }: { className?: string }) => {
    return (
      <CleanRow
        className={`grid__column-12--sm grid__column-12--md grid__column-12 ${className}`}
      >
        <h2>Heads Up!</h2>
      </CleanRow>
    );
  }
)`
  display: flex;
  justify-content: center;
`;

const DeleteNotificationBodyText = styled(
  ({ className = '' }: { className?: string }) => {
    return (
      <CleanRow
        className={`grid__column-12--sm grid__column-12--md grid__column-12 ${className}`}
      >
        <p>
          You are about to delete your status notification. You cannot undo this
          action. Are you sure you want to continue?
        </p>
      </CleanRow>
    );
  }
)`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`;

export const DeleteNotification: FC<Props> = ({
  openModal,
  closeFn,
  notification,
  callSearch,
}) => {
  const modalWidth = useMatchingMedia('lg') ? 471 : 368;
  const authData = useContext(AuthContext);

  const handleDelete = async () => {
    if (notification) {
      for (
        let index = 0;
        index < notification.notificationPreferences.length;
        index++
      ) {
        const preference = notification.notificationPreferences[index];
        const departureTimeZone = notification.departureTimeZone
          ? notification.departureTimeZone
          : 'America/Chicago';
        const params = {
          crew_member_id: authData.userId,
          flight_number: notification.flightNumber,
          departure_station: notification.departureCode,
          departure_date: formatInTimeZone(
            notification.departureDatetime,
            departureTimeZone,
            'yyyy-MM-dd'
          ), // departure_date must be in flight departure local time for subscriptions,
          channel_type: preference.notificationType,
          channel_address: preference.notificationAddress,
        };

        await backendApiService.delete<DeleteSubscriptionsResponse>(
          `/subscriptions`,
          {
            params: params,
          }
        );
      }
      closeFn();
      callSearch();
    }
  };

  const CancelButton: FC = () => (
    <Button id={'cancel'} buttonType={'tertiary'} onClick={closeFn}>
      Cancel
    </Button>
  );

  const ActionButton: FC = () => (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Button id={'delete'} buttonType={'primary'} onClick={handleDelete}>
      Delete
    </Button>
  );

  const bodyContent = (
    <CleanGrid>
      <DeleteNotificationHeader />
      <DeleteNotificationBodyText />
      <ModalNotificationButtonsRow
        CancelButton={CancelButton}
        ActionButton={ActionButton}
      />
    </CleanGrid>
  );
  return (
    <Modal
      open={openModal}
      closeFn={closeFn}
      bodyContent={bodyContent}
      width={modalWidth}
      showClose={false}
    />
  );
};
