import { FC } from 'react';
import { Button } from '@swacl/core';
import styled from '@emotion/styled';
import { IStyledProps } from '../../shared/utilities';
import { CleanRow } from '../shared/CleanGrid';

interface Props {
  disabled: boolean;
  onClick: () => void;
}

const CreateNotificationButtonBase: FC<Props & IStyledProps> = ({
  disabled,
  className = '',
  onClick,
}) => {
  return (
    <CleanRow className={className}>
      <div
        className={`grid__column-9--sm grid__column-9--md grid__column-9`}
      ></div>
      <div className={`grid__column-3--sm grid__column-3--md grid__column-3`}>
        <Button // probably simpler to create a button component
          id={'create-notification'}
          buttonType={'primary'}
          onClick={onClick}
          disabled={disabled}
        >
          Create
        </Button>
      </div>
    </CleanRow>
  );
};

export const CreateNotificationButton = styled(CreateNotificationButtonBase)`
  margin-top: 12px;
`;
